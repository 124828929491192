export interface EventResponseBody {
  description?: string;
  end: string;
  freeBusyStatus?: FreeBusyStatus;
  id: string;
  isDeleted: false;
  object: 'event';
  provider: Provider;
  start: string;
  status: Status;
  title?: string;
}

export enum Status {
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Tentative = 'tentative',
}

export enum FreeBusyStatus {
  Free = 'free',
  Busy = 'busy',
}

export enum Provider {
  Google = 'google',
  Self = 'self',
}

export interface AvailabilityResponseBody {
  accounts: string[];
  end: string;
  object: 'availability';
  start: string;
}

export interface EventInsertRequestBody {
  attendees?: EventAttendee[];
  description?: string;
  end: string;
  freeBusyStatus?: FreeBusyStatus;
  start: string;
  status: Status;
  title?: string;
}

export interface EventAttendee {
  email: string;
  status?: EventAttendeeStatus;
}

export enum EventAttendeeStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Pending = 'pending',
  Tentative = 'tentative',
}
