import { AfterContentInit, Component, ContentChild, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TimelineContentDirective, TimelineMarkerDirective, TimelineOppositeDirective } from './directives';

@Component({
  selector: 'fullstack-events-timeline',
  templateUrl: './timeline.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TimelineComponent implements OnInit, AfterContentInit {
  @ContentChild(TimelineMarkerDirective) markerTemplate: TimelineMarkerDirective;
  @ContentChild(TimelineContentDirective) contentTemplate: TimelineContentDirective;
  @ContentChild(TimelineOppositeDirective) oppositeTemplate: TimelineOppositeDirective;

  @Input() events: any[];
  @Input() align = 'left';
  @Input() layout = 'vertical';

  ngOnInit(): void {}

  ngAfterContentInit() {}
}
