import { Pipe, PipeTransform } from '@angular/core';
import { CommonRoles, IJwtPayload } from 'src/libs/shared/common/src';

@Pipe({
  name: 'canBeViewedBy',
})
export class CanBeViewedByPipe implements PipeTransform {
  transform(roles: string[], user: IJwtPayload): unknown {
    if (!roles || roles.length === 0) {
      return true;
    }
    if (user.roles.includes(CommonRoles.IS_ADMIN)) {
      return true;
    }
    return roles.some((r) => user.roles.includes(r));
  }
}
