import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAddress, IEvent } from '@renovars/common';
import { EventService } from '@renovars/core-ng';
import { MessageService } from 'primeng/api';
import { catchError, of, tap } from 'rxjs';
import { RecruitFimmEventsApiService } from 'src/app/services/recruitfimm-events-api.service';
import {
  IBaseCandidate,
  RecruitFimmEventType,
} from 'src/libs/shared/recruit/src';

@Component({
  selector: 'candidati-create-event-modal',
  template: `
    <div mat-dialog-content>
      <div class="row">
        <fullstack-checkbox-button
          [(ngModel)]="avabilityType"
          id="top-btn-modal"
        >
          <checkbox [value]="'classic'" [label]="'classico'"></checkbox>
          <checkbox [value]="'manual'" [label]="'manuale'"></checkbox>
        </fullstack-checkbox-button>
      </div>
      <div *ngIf="avabilityType === 'classic'" class="child-height">
        <candidati-address-event-modal
          [data]="data"
          (saved)="onClassicSave($event)"
        ></candidati-address-event-modal>
      </div>
      <div *ngIf="avabilityType === 'manual'" class="child-height">
        <candidati-manual-event-modal
          [data]="data"
          (saved)="onManualSave($event)"
        ></candidati-manual-event-modal>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button
        type="button"
        class="btn btn-default rounded m-1"
        (click)="close()"
      >
        Annulla
      </button>
      <button
        type="submit"
        class="btn btn-warning rounded m-1"
        (click)="saveManual()"
        *ngIf="avabilityType === 'manual'"
      >
        Crea
      </button>
      <button
        type="submit"
        class="btn btn-warning rounded m-1"
        (click)="saveClassic()"
        *ngIf="avabilityType === 'classic'"
      >
        Crea
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      #top-btn-modal {
        display: flex;
        justify-content: flex-end;
      }
      .child-height {
        height: 60vh;
      }
    `,
  ],
})
export class CandidatiCreateEventModalComponent implements OnInit {
  avabilityType: 'classic' | 'manual' = 'classic';
  payload: {
    address: IAddress;
    start: Date;
    end: Date;
    user: string;
    medium: number;
    connectionInfo?: string;
  } = null;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { candidate: IBaseCandidate; type: RecruitFimmEventType },
    public dialogRef: MatDialogRef<CandidatiCreateEventModalComponent>,
    private messageService: MessageService,
    private eventService: EventService,
    private eventApi: RecruitFimmEventsApiService
  ) {}

  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
  }
  onManualSave(event: {
    address: IAddress;
    start: Date;
    end: Date;
    user: string;
    medium: number;
  }) {
    this.payload = event;
  }
  onClassicSave(event: {
    address: IAddress;
    start: Date;
    end: Date;
    user: string;
    medium: number;
  }) {
    this.payload = event;
  }
  saveManual() {
    if (!this.payload?.user) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Seleziona un utente',
      });
      return;
    }
    if (!this.payload?.address) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Seleziona un indirizzo',
      });
      return;
    }
    if (!this.payload?.start) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Seleziona una data',
      });
      return;
    }
    if (this.payload?.medium === 1 && !this.payload?.connectionInfo) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Inserisci un Link',
      });
      return;
    }
    const data = { ...this.payload, contact: this.data.candidate.contact };
    this.save(data);
  }
  saveClassic() {
    if (!this.payload?.user) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Seleziona un utente',
      });
      return;
    }
    if (!this.payload?.address) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Seleziona un indirizzo',
      });
      return;
    }
    if (!this.payload?.start) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Seleziona una data',
      });
      return;
    }
    const data = { ...this.payload, contact: this.data.candidate.contact };
    this.save(data);
  }

  private save(event: IEvent) {
    let source$;
    if (this.data.type == RecruitFimmEventType.SECONDO_APPUNTAMENTO) {
      source$ = this.eventApi.createTraining(event);
    } else if (this.data.type == RecruitFimmEventType.APPUNTAMENTO) {
      source$ = this.eventService.create(event);
    }
    source$
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Evento creato con successo',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Errore durante la creazione',
          });
          return of(err);
        })
      )
      .subscribe(() => this.dialogRef.close());
  }
}
