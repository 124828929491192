import { IEvent } from '../events';

export interface ITimelineItem {
  oldState?: any;
  newState?: any;
  insertDate?: Date;
  userId?: string;
  username?: string;
  mongoCollection?: string;
  docId?: string;
  opType?: string;
  migrationId?: string;
}
