import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  getEnumKeyByEnumValue,
  getPossibleRecruitFimmChoiseStates,
  IBaseCandidate,
  RecruitFimmEventType,
  RecruitFimmRoles,
  RecruitFimmStateCodes,
  RecruitFimmStateLabel,
} from 'src/libs/shared/recruit/src';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { CandidatiCreateEventModalComponent } from '../features/candidati/modals/candidati-create-event-modal.component';
import { CandidatiEditStatoModalComponent } from '../features/candidati/modals/candidati-edit-stato-modal.component';

@Injectable()
export class CandidatiMenuService {
  private codeLabels = RecruitFimmStateLabel;
  private base: MenuItem[] = [];
  private dialogSub = new BehaviorSubject(null);
  constructor(private dialog: MatDialog) {}

  private buildMenuByRole(
    candidate: IBaseCandidate,
    role: 'HR' | 'SM' | 'ADMIN'
  ): MenuItem[] {
    const choices = getPossibleRecruitFimmChoiseStates(
      candidate.status.code as RecruitFimmStateCodes,
      role
    );
    return choices.map((v) => ({
      label: this.codeLabels[v],
      icon: v.includes('KO')
        ? 'pi pi-ban'
        : v.includes('OK')
        ? 'pi pi-check-circle'
        : 'pi pi-calendar',
      command: (event) => {
        if (!event.item.label) {
          return;
        }
        const status = getEnumKeyByEnumValue(
          RecruitFimmStateLabel,
          event.item.label
        );
        if (status.includes(RecruitFimmStateCodes.HR_SM_COLLOQUIO_FISSATO)) {
          this.dialog
            .open(CandidatiCreateEventModalComponent, {
              data: {
                candidate: candidate,
                type: RecruitFimmEventType.APPUNTAMENTO,
              },
            })
            .afterClosed()
            .subscribe((v) => this.dialogSub.next(v));
        } else if (status.includes(RecruitFimmStateCodes.SM_IN_TRATTATIVA)) {
          this.dialog
            .open(CandidatiCreateEventModalComponent, {
              data: {
                candidate: candidate,
                type: RecruitFimmEventType.SECONDO_APPUNTAMENTO,
              },
            })
            .afterClosed()
            .subscribe((v) => this.dialogSub.next(v));
        } else {
          this.dialog
            .open(CandidatiEditStatoModalComponent, {
              data: {
                candidate: candidate,
                statusCode: status,
              },
            })
            .afterClosed()
            .subscribe((v) => this.dialogSub.next(v));
        }
      },
    }));
  }
  getPossibleMenu(candidate: IBaseCandidate, roles: string[]) {
    let cmItems = [];
    if (
      roles.includes(RecruitFimmRoles.HR_OPERATOR) ||
      roles.includes(RecruitFimmRoles.HR_JR)
    ) {
      cmItems = this.base.concat(this.buildMenuByRole(candidate, 'HR'));
    }
    if (roles.includes(RecruitFimmRoles.CC_MANAGER)) {
      cmItems = this.base.concat(this.buildMenuByRole(candidate, 'SM'));
    }
    if (roles.includes(RecruitFimmRoles.ADMIN)) {
      cmItems = this.base.concat(this.buildMenuByRole(candidate, 'ADMIN'));
    }
    return cmItems;
  }
  onDialogClose() {
    return this.dialogSub.asObservable();
  }
}
