import { Inject, Pipe, PipeTransform } from '@angular/core';
import { IUser } from 'src/libs/shared/common/src';
import { AppConfigService } from '../../app-config';
import { NetworkModuleOptions, NETWORK_CONFIG_PROVIDER } from '../module.options';

@Pipe({ name: 'getRoles' })
export class GetRolesPipe implements PipeTransform {
  constructor(
    private config: AppConfigService,
    @Inject(NETWORK_CONFIG_PROVIDER) private options: NetworkModuleOptions
  ) {}

  transform(user: IUser) {
    if (user && user.registrations) {
      const reg = user.registrations.find((r) => r.applicationId == this.config.getApplicationId());
      if (this.options?.labels) {
        const roles = reg.roles.filter((r) => r.startsWith('is_'));
        return roles.map((r) => this.options.labels[r]).filter((r) => !!r);
      }
      if (reg) {
        let roles = reg.roles.filter((r) => r.startsWith('is_'));

        roles = roles.map((r) => {
          r = r.replace('is_', '');
          r = r.replace('_', ' ');
          r = r.toLocaleUpperCase();
          return r;
        });

        return roles.join(', ');
      }
    }
  }
}
