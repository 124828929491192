export interface IFileServerModel<T = any> {
  id: string;
  deleted: boolean;
  filename: string;
  mimeType: string;
  originalName: string;
  path: string;
  size: number;
  metadata: T;
  public: boolean;
}
