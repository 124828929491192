import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAddress } from '@renovars/common';

@Component({
  selector: 'candidati-manual-event-modal',
  template: `
    <calendar-slot-selector-modal
      (valueChange)="onDateSelected($event)"
    ></calendar-slot-selector-modal>
  `,
  styles: [],
})
export class CandidatiManualEventModalComponent implements OnInit {
  @Output() saved = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  onDateSelected(event: {
    address: IAddress;
    start: Date;
    end: Date;
    user: string;
    medium: number;
    connectionInfo: string;
  }) {
    this.saved.emit(event);
  }
}
