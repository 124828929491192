<fullstack-page-layout [registration]="(user$ | async)?.registrations">
  <div sidebar>
    <fullstack-sidebar-menu
      [options]="{
        logoUrl: 'assets/img/logo.png',
        logoMinUrl: 'assets/img/logo-min.png'
      }"
      [user]="(user$ | async)?.given_name + ' ' + (user$ | async)?.family_name"
      (logout)="logout()"
    >
      <sidebar-item
        [label]="'Utenti'"
        [url]="'./users'"
        *permissions="['user_manager']"
        [icon]="'fa-users'"
      >
        <sidebar-item [label]="'Lista'" [url]="'list'"></sidebar-item>
      </sidebar-item>
      <!-- <sidebar-item
        [label]="'Anagrafica'"
        [url]="'./contacts'"
        *permissions="['contact_manager']"
        [icon]="'fa-id-card'"
      ></sidebar-item> -->
      <sidebar-item
        [label]="'Dashboard'"
        [url]="'./dashboard'"
        [icon]="'fa-chart-line'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Agente'"
        [url]="'./candidati'"
        *permissions="['candidate_manager']"
        [icon]="'fa-user-tie'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Network'"
        [url]="'./network'"
        *permissions="['network_manager']"
        [icon]="'fa-project-diagram'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Appuntamenti'"
        [url]="'./events'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-alt'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Calendario'"
        [url]="'./calendar'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-day'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Sedi'"
        [url]="'./site'"
        *permissions="['is_admin']"
        [icon]="'fa-home'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Fonti'"
        [url]="'./sources'"
        *permissions="['is_admin']"
        [icon]="'fas fa-ad'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Documenti'"
        [url]="'./filemanager'"
        [icon]="'fas fa-file'"
      ></sidebar-item>
    </fullstack-sidebar-menu>
  </div>
  <div content>
    <router-outlet></router-outlet>
  </div>
</fullstack-page-layout>
