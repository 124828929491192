import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanAnswer, BooleanAnswerEnum } from 'src/libs/shared/common/src';
import { FormElement } from '../core';

interface ButtonOption {
  name: string;
  value: BooleanAnswer;
}

@Component({
  selector: 'fullstack-input-select-button',
  templateUrl: './input-select-button.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectButtonComponent),
      multi: true,
    },
  ],
  styles: [
    `
      .p-button {
        padding: 0.3rem 1rem;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputSelectButtonComponent extends FormElement {
  @Input() options: { label: string };
  buttonOptions: ButtonOption[];

  constructor() {
    super();

    this.buttonOptions = [
      { name: 'Sí', value: true },
      { name: 'No', value: false },
      { name: 'n.d.', value: BooleanAnswerEnum.ND },
    ];
  }
}
