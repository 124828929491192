import { IEvent } from 'src/libs/shared/common/src';
import { ICandidate } from '../candidates';

export interface IRecruitEvent extends IEvent {
  candidate: string | ICandidate;
  candidates?: ICandidate[];
  mailText?: string;
  mailVisible?: boolean;
  sendMail?: boolean;
  adminMessage?: {
    to: string; //storemanage id,
    msg: string;
  };
  excludeFromStat?: boolean;
  cache?: any;
}

export enum RecruitEventType {
  APPUNTAMENTO = 1,
  AFFIANCAMENTO = 2,
}
