import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { IUser } from 'src/libs/shared/common/src';

@Component({
  selector: 'fullstack-input-user',
  templateUrl: './input-user.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputUserComponent),
      multi: true,
    },
  ],
  styles: [
    `
      .image-circle {
        overflow: hidden;
        padding: 4px;
        height: 150px;
        width: 150px;
        border-radius: 300px;
        overflow: hidden;
        background: #ffffff;
      }
    `,
  ],
})
export class InputUserComponent implements ControlValueAccessor, OnInit {
  @Input() set formInfoState(formInfoState) {
    this._formInfoState = formInfoState;
    /* setFieldsProperties(this._formInfoState, this.form); */
  }
  @Input() imageStorePath: string;
  profileImage: any;
  userId: string;

  get formInfoState() {
    return this._formInfoState;
  }

  _formInfoState: ValidationErrors = [];

  constructor(private fb: UntypedFormBuilder /*  private userMetaService: UserMetaService */) {}

  form = this.fb.group({
    firstName: new UntypedFormControl('', []),
    lastName: new UntypedFormControl('', []),
    username: new UntypedFormControl('', []),
    email: new UntypedFormControl('', []),
    birthDate: new UntypedFormControl('', []),
    password: new UntypedFormControl('', []),
    mobilePhone: new UntypedFormControl('', []),
    preferredLanguages: new UntypedFormControl('', []),
    timezone: new UntypedFormControl('', []),
    active: new UntypedFormControl('', []),
    parentEmail: new UntypedFormControl('', []),
    memberships: new UntypedFormControl('', []),
    registrations: new UntypedFormControl(null, []),
    tenantId: new UntypedFormControl('', []),
  });

  propagateChange: any = () => {};

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.propagateChange(val);
    });

    this.form.disable();
  }

  /* Metodi per ReactiveForm */
  writeValue(value: IUser) {
    if (value) {
      this.userId = value.id;
      this.getProfileImage();
      this.form.patchValue(value);
    }
  }

  onImgProfileSelect(event) {
    /* if (this.userId) {
      const file = event.target.files[0];
      let observable = of(true);
      if (this.profileImage) {
        observable = this.userMetaService.deleteProfileImage(this.profileImage.id);
      }
      observable
        .pipe(switchMap(() => this.userMetaService.uploadProfileImage(this.userId, file)))
        .subscribe(() => {
          this.getProfileImage();
        });
    } */
  }

  onImgProfileDelete() {
    /*  if (this.profileImage) {
      this.userMetaService.deleteProfileImage(this.profileImage.id).subscribe(() => {
        this.getProfileImage();
      });
    } */
  }

  getProfileImage() {
    /* if (this.userId) {
      this.userMetaService.getProfileImage(this.userId).subscribe((res) => (this.profileImage = res[0]));
    } */
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {}
}
