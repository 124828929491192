import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'boardFilter' })
export class BoardFilterPipe implements PipeTransform {
  constructor() {}

  transform(list: Array<any>, field: string, value: string) {
    if (list && field && value) {
      return list.filter((l) => l[field] === value);
    }
  }
}
