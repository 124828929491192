export interface IStatisticStoreManagerTotal {
  candidatesAssigned: number;
  candidatesToWork: number;
  candidatesInProgress: number;
  candidatesOk: number;
  candidatesInterviewKO: number;
  candidatesInterviewOK: number;
  candidatesCoachingSet: number;
  candidatesCoachingInProgress: number;
  candidatesCoachingKO: number;
  candidatesInterviewSet: number;
}
