import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { IEntityMapItem } from '../core';
declare let google;

@Component({
  selector: 'fullstack-entities-map',
  templateUrl: './entities-map.component.html',
  styleUrls: ['./entities-map.component.scss'],
})
export class EntitiesMapComponent implements OnChanges {
  @Input() options = {
    center: { lat: 41.909986, lng: 12.3959159 },
    zoom: 7,
    styles: 'assets/img/marker-1.svg',
  };
  @Input() height = '100vh';
  map: any;
  overlays: any = [];
  markerImage: { url: string; scaledSize: any };
  markers: any[] = [];
  @Input() entities: IEntityMapItem[];
  @Input() overlayEntities: [];
  clusters: any;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.setMarker(null);
  }

  private initMap() {
    this.markerImage = {
      url: 'assets/img/marker-1.svg',
      scaledSize: new google.maps.Size(60, 71),
    };
  }

  setMap(event) {
    this.map = event.map;
    this.initMap();
    this.geolocateNearPosition();

    this.changeDetector.detectChanges();
  }

  setMarker(coords) {
    if (!this.map) return;
    const bounds = new google.maps.LatLngBounds();
    this.overlays = [];
    if (this.clusters) {
      this.clusters.removeMarkers(this.markers);
    }
    this.markers = [];
    this.overlays = this.overlayEntities;

    /*     if (this.overlays.length > 0) {
          this.overlays.forEach((marker) => {
            bounds.extend(marker.getPosition());
          });
        }
     */
    this.map.setOptions(this.options);
    // this.map.fitBounds(bounds);
    const { zoom } = this.options;
    google.maps.event.addListenerOnce(this.map, 'bounds_changed', function (_event) {
      this.setZoom(zoom);
    });

    if (this.entities && this.entities.length > 0) {
      this.entities.forEach((entity) => {
        if (entity.address && entity.address.coords && entity.address.coords.length > 1) {
          const infowindow = new google.maps.InfoWindow({
            content: entity.contentInfoWindowHTML,
          });
          const marker = new google.maps.Marker({
            position: { lat: entity.address.coords[0], lng: entity.address.coords[1] },
            icon: 'assets/img/map-pin.png',
            title: entity.name,
          });
          marker.addListener('click', () => {
            infowindow.open({
              anchor: marker,
              map: this.map,
              shouldFocus: false,
            });
          });
          this.markers.push(marker);
        }
      });
    }

    this.clusters = new MarkerClusterer(this.map, this.markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    });
  }

  geolocateNearPosition() {
    const coords = [41.909986, 12.3959161];
    this.setMarker(coords);
  }
}
