<h5 *ngIf="options?.label">{{ options?.label }}:</h5>
<div class="d-flex flex-wrap">
  <div *ngFor="let c of checkboxButtons; let i = index" class="m-1 no-gutter">
    <button
      type="button"
      class="btn btn-outline-secondary btn-xs matt rounded"
      [ngClass]="{ 'btn-secondary': i == selectedIndex }"
      (click)="onSelect(i)"
      [disabled]="isDisabled"
    >
      {{ c.label }}
    </button>
  </div>
</div>
