import { Pipe, PipeTransform } from '@angular/core';
import { ITimelineItem } from 'src/libs/shared/common/src';
import _ from 'lodash';
import moment from 'moment';
@Pipe({ name: 'historyDates' })
export class HistoryDatesPipe implements PipeTransform {
  transform(value: ITimelineItem[], criteria: any) {
    let res: ITimelineItem[] = [];
    if (value && criteria) {
      res = value.filter((v) => {
        let cKeys = Object.keys(criteria);
        return cKeys.every((ck) => {
          if (v.newState) {
            return _.get(v.newState, ck) == criteria[ck];
          }
        });
      });
    }

    return res.reduce((a, b) => a + moment(b.insertDate).format('LL'), '');
  }
}
