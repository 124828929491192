import { IContactIndividual, IEvent, IUser } from 'src/libs/shared/common/src';
import { IBaseCandidate } from '../candidates';

export default interface ICofEvents extends IEvent {
  candidate: string;
  userObj?: IUser;
  cache: {
    contact: IContactIndividual;
    candidate: IBaseCandidate;
  };
}

export enum CofEventType {
  APPUNTAMENTO = 1,
  APPUNTAMENTO_RIFISSATO = 2,
  FORMAZIONE = 3,
  FORMAZIONE_RIFISSATA = 4,
}
export const CofEventTypeLabels = {
  1: 'Appuntamento',
  2: 'Appuntamento Rifissato',
  3: 'Formazione',
  4: 'Formazione Rifissata',
};

export enum CofEventStateEnum {
  FISSATO = 1,
  ANNULLATO = 3, //DONT CHANGE uguale a FIMM
}

export const CofEventStateLabels = {
  1: 'Fissato',
  3: 'Annullato',
};
