<p-inputNumber
  [(ngModel)]="value"
  [showButtons]="true"
  [step]="1"
  buttonLayout="horizontal"
  decrementButtonClass="btn btn-value"
  incrementButtonClass="btn btn-value"
  incrementButtonIcon="fas fa-plus"
  decrementButtonIcon="fas fa-minus"
></p-inputNumber>
