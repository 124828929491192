import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  IBaseCandidate,
  RecruitFimmEventType,
} from 'src/libs/shared/recruit/src';
import {
  EventService,
  SiteService,
} from 'src/libs/fe/core-ng/src/lib/features';
import dayjs from 'dayjs';
import { IEvent, ISite } from 'src/libs/shared/common/src';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'candidati-address-event-modal',
  template: `
    <form [formGroup]="formAppointment" (submit)="save()">
      <div class="row">
        <div class="col-12">
          <fullstack-checkbox-button
            formControlName="medium"
            id="top-classic-modal"
          >
            <checkbox [value]="0" [label]="'In presenza'"></checkbox>
            <checkbox [value]="1" [label]="'Online'"></checkbox>
          </fullstack-checkbox-button>
        </div>
        <div class="col-12 mt-2">
          <label for="locality">{{
            formAppointment.get('medium').value === 0 ? 'Indirizzo' : 'Località'
          }}</label>
          <fullstack-input-select-box
            formControlName="address"
            [options]="{ label: 'Sede candidatura *' }"
            [isAddress]="true"
          >
            <sb-option [value]="s.address" *ngFor="let s of sites | async">{{
              s.name
            }}</sb-option>
          </fullstack-input-select-box>
        </div>

        <div class="col-6 mt-1">
          <fullstack-datepicker
            formControlName="date"
            [options]="{ label: 'Data' }"
            (ngModelChange)="checkEventAvailability()"
          ></fullstack-datepicker>
        </div>

        <div class="col-6 mt-1">
          <fullstack-input-select-box
            [options]="{ label: 'Team manager' }"
            [formControl]="slot"
          >
            <ng-container *ngFor="let s of slotList">
              <sb-option
                [value]="{ account: s.userId, slot: a }"
                *ngFor="let a of s.slots"
              >
                {{ a | date : 'short' }} - {{ s.email }}
              </sb-option>
            </ng-container>
          </fullstack-input-select-box>
        </div>

        <div class="col-12 mt-1">
          <fullstack-input-textarea
            [options]="{ label: 'Note', rows: 2 }"
            formControlName="note"
          >
          </fullstack-input-textarea>
        </div>
        <div
          class="col-12 mt-1"
          *ngIf="formAppointment.get('medium').value === 1"
        >
          <fullstack-input-textarea
            [options]="{ label: 'Link riunione', rows: 2 }"
            formControlName="connectionInfo"
          >
          </fullstack-input-textarea>
        </div>
      </div>
    </form>
  `,
  styles: [
    `
      #top-classic-modal {
        display: flex;
        justify-content: flex-end;
      }
    `,
  ],
})
export class CandidatiAddressEventModalComponent implements OnInit {
  formAppointment: UntypedFormGroup;
  slotList: any[];
  slot: UntypedFormControl;
  partecipants: UntypedFormControl;
  RecruitFimmEventType = RecruitFimmEventType;
  contacts: { id: string; label: string }[];
  sites: Observable<ISite[]>;
  @Input() data: { candidate: IBaseCandidate; type: RecruitFimmEventType } =
    null;
  @Output() saved = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    public dialogRef: MatDialogRef<CandidatiAddressEventModalComponent>,
    private siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.formAppointment = this.fb.group({
      contact: new UntypedFormControl('', []),
      candidate: new UntypedFormControl('', []),
      start: new UntypedFormControl('', []),
      end: new UntypedFormControl('', []),
      address: new UntypedFormControl('' || '', []),
      user: new UntypedFormControl('', []),
      note: new UntypedFormControl('', []),
      date: new UntypedFormControl(null, []),
      medium: new UntypedFormControl(0, []),
      connectionInfo: new UntypedFormControl('', []),
    });
    this.slot = new UntypedFormControl({}, []);
    this.sites = this.siteService.findAll();
    this.formAppointment.valueChanges.subscribe(() => this.save());
    this.slot.valueChanges.subscribe(() => this.save());
  }
  checkEventAvailability() {
    const eventForm = cloneDeep(this.formAppointment.value);
    if (eventForm.address && eventForm.date) {
      const request: Partial<IEvent> = {
        start: dayjs(eventForm.date).startOf('day').toDate(),
        end: dayjs(eventForm.date).endOf('day').toDate(),
        address: eventForm.address,
      };
      this.eventService.checkEventAvailability(request).subscribe((res) => {
        this.slotList = res;
      });
    }
  }
  save() {
    if (!this.formAppointment.valid || !this.slot.valid) return;
    const eventForm = cloneDeep(this.formAppointment.value);
    const slot: { account: string; slot: string } = this.slot.value;
    eventForm.start = dayjs(slot.slot).toDate();
    eventForm.end = dayjs(slot.slot).add(1, 'hour').toDate();
    eventForm.user = slot.account;
    eventForm.type = this.data.type;
    eventForm.contact = this.data.candidate.contact;
    eventForm.connectionInfo = eventForm.connectionInfo;
    this.saved.emit(eventForm);
  }
  close() {
    this.dialogRef.close();
  }
}
