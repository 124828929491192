import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  EventType,
  IAddress,
  IAppointmentSearchDTO,
  IAvailabilitySlot,
  IEvent,
  IPaginateRequest,
  IUser,
} from '@renovars/common';
import { RecruitFimmRoles } from '@renovars/common/recruit';
import { CalendarioService } from '@renovars/fe-core-libs/services/calendario.service';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';

@Component({
  selector: 'calendar-slot-selector-modal',
  templateUrl: './calendar-slot-selector-modal.component.html',
  styleUrls: ['./calendar-slot-selector-modal.component.scss'],
})
export class CalendarSlotSelectorModalComponent
  implements AfterViewInit, OnInit
{
  list: IEvent[] = [];
  selectedUserAvailability: IAvailabilitySlot[] = [];
  storeManagers$: Observable<IUser[]>;
  medium = 0;
  address: IAddress = null;
  connectionInfo: string = null;
  currentSelectedDate = null;
  @Output() valueChange = new EventEmitter();
  paginateRequest: IPaginateRequest<IAppointmentSearchDTO> = {
    form: { showOnlyValidEvents: true, canSyncGraph: true },
    skip: 0,
    limit: 1000,
  };
  constructor(
    public dialogRef: MatDialogRef<CalendarSlotSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected calendarioService: CalendarioService,
    protected usersService: UsersService
  ) {}
  ngOnInit(): void {
    this.storeManagers$ = this.usersService.getUsersListDownline(
      [RecruitFimmRoles.ADMIN],
      null
    );
  }

  ngAfterViewInit(): void {
    this.search();
  }

  viewChange(data) {
    this.paginateRequest.form.eventStartRange = {
      start: data.currentStart,
      end: data.currentEnd,
    };
    this.search();
  }

  search() {
    this.calendarioService
      .search(this.paginateRequest, false)
      .subscribe((res) => (this.list = res.data));
  }
  searchByAddress(address: IAddress) {
    this.paginateRequest.form.address = address.formatted_address;
    this.address = address;
    this.search();
    const payload = this.createPayload(this.currentSelectedDate);
    this.valueChange.emit(payload);
  }

  onDateClick(event) {
    this.currentSelectedDate = event.date;
    const payload = this.createPayload(event.date);
    this.valueChange.emit(payload);
  }
  onMediumChange(event) {
    this.medium = event;
    const payload = this.createPayload(event.date);
    this.valueChange.emit(payload);
  }
  private createPayload(date: Date) {
    if (!date || date.getTime() === 0) return;
    const payload = {
      user: this.paginateRequest.form.userId,
      address: this.address,
      medium: this.medium,
      start: dayjs(date).toDate(),
      end: dayjs(date).add(1, 'hour').toDate(),
      connectionInfo: this.connectionInfo,
    };
    return payload;
  }

  searchByUser(userId: string) {
    this.selectedUserAvailability = null;
    this.paginateRequest.form.network = undefined;
    this.paginateRequest.form.userId = userId;
    this.search();
    const payload = this.createPayload(this.currentSelectedDate);
    this.valueChange.emit(payload);
  }
  changeInfo(event: string) {
    this.connectionInfo = event;
    const payload = this.createPayload(this.currentSelectedDate);
    this.valueChange.emit(payload);
  }

  getColor(event: IEvent) {
    switch (event.type) {
      case 1:
        return '#29b6f6';
      case 2:
        return '#66bb6a';
      case EventType.PERSONALE:
        return '#FF5252';
      default:
        return '#ffa726';
    }
  }

  getEvent(e) {
    let title = '';
    if (e?.user && typeof e?.user == 'object') {
      title = e.contact?.businessName
        ? e?.user.firstName +
          ' ' +
          e?.user.lastName +
          ' | ' +
          e?.contact?.businessName
        : e?.user.firstName + ' ' + e?.user.lastName;
      title += ' | ';
    }

    if (
      e.cache?.contact &&
      e.cache.contact.firstName &&
      e.cache.contact.lastName
    ) {
      title += e.cache.contact.firstName + ' ' + e.cache.contact.lastName;
    }

    if (e.type === EventType.PERSONALE) {
      if (e.syncedFromOutlookAt) {
        title = `[O] ${e.note}: ${e?.user?.firstName} ${e?.user?.lastName}`;
      } else {
        title = `Riservato da ${e?.user?.firstName} ${e?.user?.lastName}`;
      }
    }

    return {
      title,
      start: e.start,
      end: e.end,
      color: this.getColor(e),
      extendedProps: e,
    };
  }
}
