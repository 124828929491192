import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Component({
  selector: 'fullstack-slider',
  templateUrl: './slider.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent extends FormElement implements OnInit {
  @Input() options: { min: number; max: number; step: number };
  value = [];

  ngOnInit(): void {
    this.value[0] = this.options?.min || 0;
    this.value[1] = this.options?.max || 0;
    if (this.options?.max && !this.options?.step) {
      this.options.step = Math.round(((this.options?.max - this.options?.min || 0) * 2) / 100);
    }
  }

  writeValue(obj: any): void {}

  slideEnd(event) {
    let values = [...event.values];
    if (values[0] == this.options?.min) {
      values[0] = undefined;
    }
    if (values[1] == this.options?.max) {
      values[1] = undefined;
    }
    this._onChange(values);
  }
}
