<div class="btn-group-lg d-inline-flex w-100">
  <ng-tempate *ngFor="let option of options; index as i">
    <input
      type="radio"
      class="btn-check"
      name="btnradio"
      [id]="'btn-radio-' + formId + '-' + i"
      autocomplete="off"
      [ngModel]="value"
      (ngModelChange)="value = $event"
      [disabled]="option.disabled"
    />
    <i *ngIf="option.icon" class="fas fa-mars fa-1x me-2" [ngClass]="option.icon"></i>
    <label
      class="btn btn-outline-medium rounded w-100"
      [ngClass]="{ 'btn-disabled': option.disabled }"
      [for]="'btn-radio-' + formId + '-' + i"
      >{{ option.label }}</label
    >
  </ng-tempate>
</div>
