export interface ObjectDTO {}

export enum SearchType {
  CONTAINS = 'contains',
  EXACT = 'exact',
}

export interface IPaginateRequest<T extends Partial<ObjectDTO>> {
  form: T;
  formOptions?: {
    [P in keyof T]?: {
      order?: number;
      searchType?: SearchType;
    };
  };
  limit?: number;
  skip?: number;
  select?: string[];
}
