import { Injectable } from '@angular/core';
import { IAppointmentSearchDTO, IPaginateRequest } from 'src/libs/shared/common/src';
@Injectable()
export class EventSharedService {
  private readonly paginateRequest: IPaginateRequest<IAppointmentSearchDTO> = { form: {} };

  getPaginateRequest() {
    return this.paginateRequest;
  }
}
