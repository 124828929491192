<fullstack-header *ngIf="!headerLess"></fullstack-header>

<ng-template header-body>
  <h1 class="tx-title-1">Appuntamenti</h1>
  <div class="row">
    <div class="text-small col-2 text-primary">tipologia</div>
    <fullstack-checkbox-button
      [(ngModel)]="paginateRequest.form.medium"
      (ngModelChange)="search()"
    >
      <checkbox [value]="0" [label]="'In presenza'"></checkbox>
      <checkbox [value]="1" [label]="'Online'"></checkbox>
      <checkbox [label]="'Tutti'"></checkbox>
    </fullstack-checkbox-button>
  </div>
  <div class="row">
    <div class="text-small col-2 text-primary">tipo</div>
    <div class="col-2"></div>
    <fullstack-checkbox-button
      [(ngModel)]="paginateRequest.form.eventType"
      (ngModelChange)="search()"
    >
      <checkbox
        [value]="eventTypeCode.APPUNTAMENTO"
        [label]="'Appuntamento'"
      ></checkbox>
      <checkbox
        [value]="eventTypeCode.SECONDO_APPUNTAMENTO"
        [label]="'Secondo Appuntamento'"
      ></checkbox>
      <checkbox [label]="'Tutti'"></checkbox>
    </fullstack-checkbox-button>
  </div>
</ng-template>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<fullstack-datatable
  [options]="{ lazy: true }"
  (lazyLoad)="onLazyLoad($event)"
  [list]="eventsList?.data"
  [totalRecords]="eventsList?.total"
  (selected)="selected = $event"
  [cmItems]="cmItems"
>
  <div t-header>
    <div t-column [width]="'250px'">
      <ng-template t-col-title>Nominativo</ng-template>
      <fullstack-input-select-box
        *t-filter
        [options]="{ autocomplete: true }"
        ngModel
        (ngModelChange)="paginateRequest.form.contactId = $event; search()"
        (inputChange)="searchContact($event)"
      >
        <sb-option [value]="i.id" *ngFor="let i of contacts$ | async">{{
          i | contactName
        }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Data inserimento</ng-template>
      <fullstack-daterangepicker
        *t-filter
        (rangeChange)="paginateRequest.form.insertDateRange = $event; search()"
      >
      </fullstack-daterangepicker>
    </div>
    <div t-column>
      <ng-template t-col-title>Telefono</ng-template>
      <fullstack-input-box
        *t-filter
        (keyup.enter)="search()"
        [(ngModel)]="paginateRequest.form.phone"
      >
      </fullstack-input-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Email</ng-template>
      <fullstack-input-box
        *t-filter
        (keyup.enter)="search()"
        [(ngModel)]="paginateRequest.form.email"
      >
      </fullstack-input-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Località</ng-template>
      <fullstack-input-box
        *t-filter
        (keyup.enter)="search()"
        [(ngModel)]="paginateRequest.form.address"
      >
      </fullstack-input-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Data inizio</ng-template>
      <fullstack-daterangepicker
        *t-filter
        (rangeChange)="paginateRequest.form.eventStartRange = $event; search()"
      >
      </fullstack-daterangepicker>
    </div>
    <div t-column>
      <ng-template t-col-title>Data fine</ng-template>
      <fullstack-daterangepicker
        *t-filter
        (rangeChange)="paginateRequest.form.eventEndRange = $event; search()"
      >
      </fullstack-daterangepicker>
    </div>
    <div t-column>
      <ng-template t-col-title>Utente</ng-template>
      <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.userId"
        (ngModelChange)="paginateRequest.form.userId = $event; search()"
      >
        <sb-option
          [key]="'id'"
          [value]="elem"
          *ngFor="let elem of usersList$ | async"
          >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
        >
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Creato Da</ng-template>
      <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.insertUserId"
        (ngModelChange)="paginateRequest.form.insertUserId = $event; search()"
      >
        <sb-option
          [key]="'id'"
          [value]="elem"
          *ngFor="let elem of usersList$ | async"
          >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
        >
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Stato</ng-template>
      <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.state"
        (ngModelChange)="paginateRequest.form.state = $event; search()"
      >
        <sb-option [value]="eventStateCode.FISSATO">Fissato</sb-option>
        <sb-option [value]="eventStateCode.ANNULLATO">Annullato</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Tipo</ng-template>
      <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.eventType"
        (ngModelChange)="paginateRequest.form.eventType = $event; search()"
      >
        <sb-option [value]="eventTypeCode.APPUNTAMENTO">Appuntamento</sb-option>
        <sb-option [value]="eventTypeCode.SECONDO_APPUNTAMENTO"
          >Secondo Appuntamento</sb-option
        >
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Tipologia</ng-template>
      <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.medium"
        (ngModelChange)="paginateRequest.form.medium = $event; search()"
      >
        <sb-option [value]="0">In presenza</sb-option>
        <sb-option [value]="1">Online</sb-option>
      </fullstack-input-select-box>
    </div>
  </div>

  <ng-template t-body let-row>
    <td>{{ row?.cache?.contact | contactName }}</td>
    <td>{{ row?.createdAt | date : "short" }}</td>
    <td>
      {{
        row?.cache?.contact?.phones && row?.cache?.contact.phones.length > 0
          ? row?.cache?.contact?.phones[0].phoneNumber
          : ""
      }}
    </td>
    <td>
      {{
        row?.cache?.contact?.emails && row?.cache?.contact?.emails.length > 0
          ? row?.cache?.contact?.emails[0].email
          : ""
      }}
    </td>
    <td>{{ row?.address?.formatted_address }}</td>
    <td>{{ row?.start | date : "short" }}</td>
    <td>{{ row?.end | date : "short" }}</td>
    <td>{{ row?.user?.id | nameById | async }}</td>
    <td>{{ row?.insertUser | nameById | async }}</td>
    <td>{{ stateLabels[row?.state] }}</td>
    <td>{{ typeLabels[row?.type] }}</td>
    <td>{{ mediumLabel[row?.medium] }}</td>
  </ng-template>
</fullstack-datatable>
