import * as XLSX from 'xlsx';

export let jsonToXlsx = (jsonData: any) => {
  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.json_to_sheet(jsonData);
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
  return rawExcel;
};

export let jsonToXlsxFile = (jsonData: any, fileName: string) => {
  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.json_to_sheet(jsonData);
  XLSX.utils.book_append_sheet(wb, newWs);
  XLSX.writeFile(wb, fileName);
};
