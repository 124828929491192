import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { IJwtPayload, IUserMeta } from 'src/libs/shared/common/src';
import { AuthServices } from 'src/libs/fe/core-ng/src/lib/services';
import { UsersService } from 'src/libs/fe/core-ng/src/lib/services/users.service';
@Component({
  selector: 'profile-additional-info',
  template: `<form [formGroup]="form" novalidate (submit)="form.valid && save()">
    <div class="content">
      <utenti-additional-data-form [parentForm]="form"></utenti-additional-data-form>
      <div class="row mt-4">
        <div class="col-sm-12 text-end">
          <button type="submit" class="btn btn-secondary rounded">Salva</button>
        </div>
      </div>
    </div>
  </form>`,
})
export class ProfileAdditionalInfoComponent implements OnInit {
  form = this.fb.group({});
  user: IJwtPayload = null;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthServices,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.authService.user().subscribe((res) => {
      this.user = res;

      this.usersService.getUserMeta(this.user.sub).subscribe((userMeta) => {
        this.form.patchValue(userMeta);
      });
    });
  }

  save() {
    const userForm: IUserMeta = this.form.value;
    this.usersService.updateUserMeta(this.user.sub, userForm).subscribe();
  }
}
