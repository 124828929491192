import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiModule } from 'src/libs/fe/ui-ng/src';
import { ContactsServices } from '../../services/contacts.services';
import { UtilsService } from '../../services/utils.services';
import { ContactComponent } from './components/contact.component';
import { ContactUpsertComponent } from './components/pages/upsert/contact-upsert.component';
import { contactsRoutes } from './contact-routing.module';
import { ContactCompanyFormComponent } from './forms/contact-company-form.component';
import { ContactFormComponent } from './forms/contact-form.component';
import { ContactIndividualFormComponent } from './forms/contact-individual-form.component';

const COMPONENTS = [
  ContactComponent,
  ContactUpsertComponent,
  ContactFormComponent,
  ContactIndividualFormComponent,
  ContactCompanyFormComponent,
];

@NgModule({
  imports: [RouterModule.forChild(contactsRoutes), FormsModule, CommonModule, ReactiveFormsModule, UiModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [ContactsServices, UtilsService],
})
export class ContactModule {}
