import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/libs/shared/common/src';
import { AppConfigService, AuthServices } from 'src/libs/fe/core-ng/src';
import { map, Observable, switchMap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'home-page',
  templateUrl: `./home-page.component.html`,
})
export class HomePageComponent implements OnInit {
  user$: Observable<IUser>;
  environment = environment;

  constructor(
    private authService: AuthServices,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    this.user$ = this.authService
      .user()
      .pipe(
        switchMap((u) =>
          this.authService
            .userRegistrations(u.sub)
            .pipe(
              map((value) => ({ ...u, registrations: value.registrations }))
            )
        )
      );
  }

  logout() {
    this.authService.logout(this.appConfig.getApplicationId());
  }
}
