<ng-template header-body>
  <div class="text-end align-items-center">
    <!-- <button class="btn btn-header" [routerLink]="['./detail']">
      <i class="fas fa-plus cl-medium fa-1x"></i>
    </button> -->
  </div>
</ng-template>

<fullstack-datatable
  [list]="sources$ | async"
  [cmItems]="cmItems"
  (selected)="selected = $event"
>
  <div t-header>
    <div t-column>
      <ng-template t-col-title>Nome</ng-template>
    </div>
    <div t-column>
      <ng-template t-col-title>Tipo</ng-template>
    </div>
    <div t-column>
      <ng-template t-col-title>Gruppo</ng-template>
    </div>
    <div t-column>
      <ng-template t-col-title>Campagna</ng-template>
    </div>
    <div t-column>
      <ng-template t-col-title>Campagna attiva</ng-template>
    </div>
  </div>

  <ng-template t-body let-row>
    <td>{{ row?.label }}</td>
    <td>{{ row?.type | domainValue : "source_lead_type" | async }}</td>
    <td>{{ row?.group | domainValue : "source_group_type" | async }}</td>
    <td>{{ row?.campaign }}</td>
    <td>{{ row?.isCampaignActive }}</td>
  </ng-template>
</fullstack-datatable>
