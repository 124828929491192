import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeLibsModule, SharedCoreModule } from 'src/libs/fe/core-ng/src';
import { UiModule } from 'src/libs/fe/ui-ng/src';
import { ChartModule } from 'primeng/chart';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipeLibsModule,
    UiModule,
    SharedCoreModule,
    ChartModule,
  ],
  providers: [],
})
export class SharedModule {}
