import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IBaseCandidate } from 'src/libs/shared/recruit/src';
import IRecruitFimmEvents from 'src/libs/shared/recruit/src/lib/interfaces/events/recruitfimm-events.interface';
import { CandidatiApiService } from '../services/candidati-api.service';
import { IContact } from '@renovars/common';

@Component({
  selector: 'recruitfimm-event-modal',
  templateUrl: `recruitfimm-event-modal.html`,
  styles: [``],
})
export class RecruitFimmEventModal implements OnInit {
  event: IRecruitFimmEvents;

  constructor(
    public dialogRef: MatDialogRef<RecruitFimmEventModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private candidateApi: CandidatiApiService
  ) {
    this.event = data.event;
  }

  ngOnInit(): void {}

  goToDetail(event: IRecruitFimmEvents) {
    const contact = event.cache.contact as IContact;
    this.candidateApi
      .getCandidateByContact$(contact._id)
      .subscribe((candidate) => {
        this.router.navigateByUrl(
          `/candidati/details/detail-info?id=${candidate._id}`
        );
      });
  }

  close() {
    this.dialogRef.close();
  }
  // move() {
  //   this.dialogRef.close({ move: true, event: this.event });
  // }
}
