<p-timeline [value]="events" [align]="align">
  <ng-template pTemplate="content" let-event>
    <ng-container
      *ngTemplateOutlet="contentTemplate.templateRef; context: {implicit : event}">
    </ng-container>
  </ng-template>
  <ng-template pTemplate="opposite" let-event>
    <ng-container
      *ngTemplateOutlet="oppositeTemplate.templateRef; context: {implicit : event}">
    </ng-container>
  </ng-template>
  <ng-template pTemplate="marker" let-event>
    <ng-container
      *ngTemplateOutlet="markerTemplate.templateRef; context: {implicit : event}">
    </ng-container>
  </ng-template>
</p-timeline>
