import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ITreeNode, IUser } from 'src/libs/shared/common/src';
import { Observable } from 'rxjs';
import { NetworkModuleOptions, NETWORK_CONFIG_PROVIDER } from '../features/network/module.options';

@Injectable()
export class NetworkService {
  constructor(
    private http: HttpClient,
    @Inject(NETWORK_CONFIG_PROVIDER) private config: NetworkModuleOptions
  ) {}

  get(): Observable<ITreeNode<IUser>[]> {
    return this.http.get<any>(`${this.config.networkURL}`);
  }
}
