import { Injectable } from '@angular/core';
import { CandidatiApiService } from './candidati-api.service';
import { IBaseCandidate, ICandidateSearch } from '@renovars/common/recruit';
import { EMPTY, concatAll, expand, map, toArray } from 'rxjs';
import * as XLSX from 'xlsx';

const jsonToXlsx = (jsonData: any) => {
  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.json_to_sheet(jsonData);
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
  return rawExcel;
};

@Injectable()
export class ChunkDownloadService {
  constructor(private candidateApi: CandidatiApiService) {}
  downloadCSV(payload: ICandidateSearch, mapping: (value: unknown) => unknown) {
    return this.getChunks(payload).pipe(
      concatAll(),
      map(mapping),
      toArray(),
      map((mapped) => this.downloadFile(mapped))
    );
  }
  private getChunks(payload: ICandidateSearch) {
    let count = 0;
    return this.candidateApi.getCandidateList$(payload).pipe(
      expand((value) => {
        if (count <= value.total) {
          payload.offset += 1000;
          count += 1000;
          return this.candidateApi.getCandidateList$(payload);
        } else {
          return EMPTY;
        }
      }),
      toArray(),
      map((values) => values.reduce((a, b) => a.concat(b.items), []))
    );
  }

  private downloadFile(data: any) {
    const replacer = (value) =>
      value === null || value === undefined ? '' : value;
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const a = document.createElement('a');
    const blob = new Blob([jsonToXlsx(data)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'candidati.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
