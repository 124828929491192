export interface ITrackingData {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
  utmId?: string;
  gclid?: string;
  rawValue?: string;
  fbScheduledAt?: Date;
  fbPurchasedAt?: Date;
  googleConvertedAt?: Date;
  googlePurchasedAt?: Date;
}
