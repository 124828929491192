import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBaseCandidate, RecruitFimmStateCodes, RecruitFimmStateLabel } from 'src/libs/shared/recruit/src';
import { CandidatiApiService } from '../../../services/candidati-api.service';

@Component({
  selector: 'candidati-edit-stato-modal',
  template: `
    <div mat-dialog-content>
      <div class="mt-2 mb-2">
        Cambia stato del candidato in:
        <span class="tx-x-bold">{{ codeLabel[statusCode] }}</span>
      </div>

      <form #statusForm="ngForm">
        <div class="row">
          <div class="col-12">
            <fullstack-input-textarea [options]="{ label: 'Note', rows: 2 }" [(ngModel)]="note" name="note">
            </fullstack-input-textarea>
          </div>
          <div class="col-12 mt-2">
            <fullstack-datepicker [options]="{ label: 'Data' }" [(ngModel)]="date" name="date" required>
            </fullstack-datepicker>
          </div>
        </div>
      </form>
    </div>

    <div mat-dialog-actions>
      <button mat-button (click)="annulla()" class="btn btn-default rounded me-3">Annulla</button>

      <button mat-button (click)="save()" cdkFocusInitial class="btn btn-warning rounded">Salva</button>
    </div>
  `,
  styles: [],
})
export class CandidatiEditStatoModalComponent implements OnInit {
  statusCode: RecruitFimmStateCodes;
  StatusCodes: RecruitFimmStateCodes;
  codeLabel = RecruitFimmStateLabel;
  candidate: IBaseCandidate;
  note: string;
  date: Date;
  constructor(
    public dialogRef: MatDialogRef<CandidatiEditStatoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { statusCode: RecruitFimmStateCodes; candidate: IBaseCandidate },
    private candidateApi: CandidatiApiService
  ) {}
  ngOnInit(): void {
    this.statusCode = this.data.statusCode;
    this.candidate = this.data.candidate;
  }
  save() {
    this.candidateApi
      .changeState(this.candidate._id, {
        code: this.statusCode,
        data: {
          date: this.date,
          note: this.note,
        },
      })
      .subscribe(() => this.close(null));
  }
  close(event) {
    this.dialogRef.close(event);
  }
  annulla() {
    this.dialogRef.close(null);
  }
}
