import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IUserMeta } from 'src/libs/shared/common/src';
import { UsersService } from 'src/libs/fe/core-ng/src/lib/services/users.service';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'utenti-additional-info',
  template: `<form [formGroup]="form" novalidate (submit)="form.valid && save()">
    <div class="content">
      <utenti-additional-data-form [parentForm]="form"></utenti-additional-data-form>
      <div class="row mt-4">
        <div class="col-sm-12 text-end">
          <button type="submit" class="btn btn-secondary rounded">Salva</button>
        </div>
      </div>
    </div>
  </form>`,
})
export class UtentiAdditionalInfo implements OnInit {
  form = this.fb.group({});

  id: string = null;
  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        tap((p) => {
          this.id = p.id;
        })
      )
      .subscribe();
  }

  save() {
    const userForm: IUserMeta = this.form.value;
    this.usersService.updateUserMeta(this.id, userForm).subscribe();
  }
}
