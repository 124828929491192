import {
  AfterContentInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IPaginateRequest } from 'src/libs/shared/common/src';
import { MenuItem, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { DatatableHeaderDirective, DatatableBodyDirective } from './datatable.directive';

@Component({
  selector: 'fullstack-datatable',
  templateUrl: './datatable.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [`./datatable.component.scss`],
})
export class DatatableComponent implements OnInit, AfterContentInit {
  @ContentChild(DatatableHeaderDirective) headerTemplate: DatatableHeaderDirective;
  @ContentChild(DatatableBodyDirective) bodyTemplate: DatatableBodyDirective;

  @ViewChild('table') table: Table;

  @Input() globalFilterFields;
  @Input() options: { singleElem: boolean; lazy: boolean };
  @Input() paginator = true;
  @Input() list: Array<any>;
  @Input() selection: any;
  @Input() cmItems: MenuItem[];
  @Input() totalRecords: number;
  @Input() maxRows = 10;
  @Input() first = 0;
  @Input() sortField;
  @Input() sortOrder;
  @Input() scrollHeight = '65vh';
  @Input() paginateRequest: IPaginateRequest<any>;
  @Input() stileClass = 'p-datatable-striped';
  @Input() frozenColumns: string[] = [];
  @Output() selected = new EventEmitter();
  @Output() lazyLoad = new EventEmitter();
  @Input() clientExport = false;

  selectedRowIndex: number;
  selectedItem;

  constructor() {}

  ngOnInit(): void {
    this.maxRows = this.maxRows || 10;
    this.totalRecords = 0;
  }

  ngAfterContentInit(): void {}

  selectRow(index) {
    this.selectedRowIndex = index;
  }

  onSelect(event) {
    this.selected.emit(event);
  }

  resetToFirstPage() {
    this.table.reset();
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (this.paginateRequest) {
      this.paginateRequest.skip = event.first;
      this.paginateRequest.limit = event.rows;
      if (event.sortField) {
        this.paginateRequest.formOptions = { [event.sortField]: { order: event.sortOrder } };
      }
    }

    this.lazyLoad.emit(event);
  }

  isFrozen(header) {
    return this.frozenColumns.includes(header.field);
  }

}
