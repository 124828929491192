import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from 'src/libs/fe/ui-ng/src';
import { SiteFormComponent } from './components/forms/site-form.component';
import { SiteDetailComponent } from './components/pages/details/site-detail.component';
import { SiteListComponent } from './components/pages/list/site-list.component';
import { SitePageComponent } from './components/pages/site-page.component';
import { ISiteOptions, SITE_OPTIONS } from './site.module.options';
import { SiteService } from './site.service';

const ROUTES: Routes = [
  {
    path: '',
    component: SitePageComponent,
    children: [
      { path: '', component: SiteListComponent },
      { path: 'detail', component: SiteDetailComponent },
    ],
  },
];

@NgModule({
  declarations: [SitePageComponent, SiteListComponent, SiteDetailComponent, SiteFormComponent],
  imports: [CommonModule, RouterModule.forChild(ROUTES), UiModule, FormsModule, ReactiveFormsModule],
  providers: [SiteService],
})
export class SiteModule {
  static forRoot(config: ISiteOptions): ModuleWithProviders<SiteModule> {
    return {
      ngModule: SiteModule,
      providers: [
        {
          provide: SITE_OPTIONS,
          useValue: config,
        },
      ],
    };
  }
}
