export interface IRole {
  description?: string;
  name: string;
  isDefault?: boolean;
  isSuperRole?: boolean;
}

export enum CommonRoles {
  IS_ADMIN = 'is_admin',
}
export const LabelRoles = {
  is_admin: 'Admin',
  is_cc_manager: 'Team manager',
};
