import type { IAddress } from '../../addresses';
import { IEmail } from '../email.interface';
import { IPhone } from '../phone.interface';

export default class CreateContactIndividualDTO {
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: string;
  birthPlace: string;
  gender: string;
  address: IAddress;
  billingAddress: IAddress;
  iban: string;
  type: string;
  fiscalCode: string;
  phones: IPhone[];
  emails: IEmail[];
  source: string;
  application: string;
  user: string;
}
