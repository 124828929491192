import { IUser } from 'src/libs/shared/common/src';

export interface IStatus {
  readonly object?: string;
  code: string;
  data?: any;
  operator?: IUser | string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IStatusHistory {
  readonly object?: string;
  /* TODO Refactor: To remove fields */
  candidateId?: string;
  status?: IStatus | string;
  /* New fields */
  collectionName?: string;
  docId?: string;
  type?: string;
  oldStatus?: any;
  newStatus?: any;
  operator?: IUser | string;
}
