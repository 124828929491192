import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { ContactTypeEnum } from 'src/libs/shared/common/src';

@Component({
  selector: 'fullstack-contact-company-form',
  templateUrl: './contact-company-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactCompanyFormComponent),
      multi: true,
    },
  ],
})
export class ContactCompanyFormComponent implements ControlValueAccessor, OnInit {
  @Input() set formInfoState(formInfoState) {
    this._formInfoState = formInfoState;
    /* setFieldsProperties(this._formInfoState, this.form); */
  }
  get formInfoState() {
    return this._formInfoState;
  }

  @Input() sources: string[];

  @Input() showSources = true;
  @Input() showPhoneType = true;
  @Input() showMailType = true;

  _formInfoState: ValidationErrors = [];

  constructor(private fb: UntypedFormBuilder) {}
  form = this.fb.group({
    id: new UntypedFormControl(null, []),
    type: new UntypedFormControl(ContactTypeEnum.COMPANY, []),
    fiscalCode: new UntypedFormControl(null, []),
    phones: new UntypedFormControl([], []),
    emails: new UntypedFormControl([], []),
    state: new UntypedFormControl(null, []),
    source: new UntypedFormControl(null, []),

    businessName: new UntypedFormControl(null, []),
    vatCode: new UntypedFormControl(null, []),
    iban: new UntypedFormControl(null, []),
    legalAddress: new UntypedFormControl(null, []),
    workingAddresses: new UntypedFormControl(null, []),
    reaNumber: new UntypedFormControl(null, []),
    reaProvince: new UntypedFormControl(null, []),
    since: new UntypedFormControl(null, []),
    atecoCode: new UntypedFormControl(null, []),
    employeesNumber: new UntypedFormControl(null, []),
    profitAmount: new UntypedFormControl(null, []),
    referencePerson: new UntypedFormGroup({
      firstName: new UntypedFormControl(null, []),
      lastName: new UntypedFormControl(null, []),
      phone: new UntypedFormControl(null, []),
      email: new UntypedFormControl(null, []),
      role: new UntypedFormControl(null, []),
    }),
    sdiCode: new UntypedFormControl(null, []),
    note: new UntypedFormControl(null, []),
  });
  isDisabled = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  propagateChange: any = () => {};

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.propagateChange(val);
    });
  }

  phonesGroupControl() {
    return this.fb.group({
      phoneNumber: new UntypedFormControl(null, []),
      type: new UntypedFormControl(null, []),
    });
  }

  emailsGroupControl() {
    return this.fb.group({
      email: new UntypedFormControl(null, []),
      type: new UntypedFormControl(null, []),
      note: new UntypedFormControl(null, []),
    });
  }

  resetFormValue() {
    this.form.reset();
    this.form.get('type').setValue(ContactTypeEnum.COMPANY);
  }

  /* Metodi per ReactiveForm */
  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value);
    } else {
      this.resetFormValue();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_fn: () => void): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
