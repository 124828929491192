import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'arrayJoin' })
export class ArrayJoinPipe implements PipeTransform {
  transform(value: any[], customField: string, separator?: string) {
    if (value) {
      if (customField) {
        value = value.map((v) => v[customField]);
      }

      return value.join(separator || ' - ');
    }

    return value;
  }
}
