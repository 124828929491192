import { Pipe, PipeTransform } from '@angular/core';
import { IContactCompany, IContactIndividual } from 'src/libs/shared/common/src';

@Pipe({ name: 'contactEmail' })
export class ContactEmailPipe implements PipeTransform {
  transform(contact: IContactCompany | IContactIndividual) {
    let res = '<email assente>';
    if (contact) {
      if ((<IContactCompany>contact).emails && (<IContactCompany>contact).emails.length > 0)
        res = (<IContactCompany>contact).emails[0].email;
    }
    return res;
  }
}
