import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Component({
  selector: 'fullstack-input-titled-box',
  templateUrl: './input-titled-box.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./input-titled-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTitledBoxComponent),
      multi: true,
    },
  ],
})
export class InputTitledBoxComponent extends FormElement implements OnInit {
  @Input() options: { disabled: boolean };
  value: { title: string; description: string } = <any>{};
  ngOnInit(): void {}
}
