import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEvent } from '@renovars/common';
import { RecruitFimmRoles } from '@renovars/common/recruit';
import { AuthServices, EventService } from '@renovars/core-ng';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { concatAll, filter, switchMap, tap, toArray } from 'rxjs';
import IRecruitFimmEvents from 'src/libs/shared/recruit/src/lib/interfaces/events/recruitfimm-events.interface';
import { RecruitFimmEventsApiService } from '../services/recruitfimm-events-api.service';

@Component({
  selector: 'update-event-date-modal',
  template: `
    <div mat-dialog-content>
      <form [formGroup]="formAppointment" (submit)="save()">
        <div class="row">
          <div class="col-12 text-center">
            <fullstack-checkbox-button formControlName="medium">
              <checkbox [value]="0" [label]="'In presenza'"></checkbox>
              <checkbox [value]="1" [label]="'Online'"></checkbox>
            </fullstack-checkbox-button>
          </div>
          <div class="col-12 mt-2">
            <label for="locality">{{
              formAppointment.get('medium').value === 0
                ? 'Indirizzo'
                : 'Località'
            }}</label>
            <fullstack-input-address-box
              formControlName="address"
              id="locality"
            >
            </fullstack-input-address-box>
          </div>

          <div class="col-6 mt-1">
            <fullstack-datepicker
              formControlName="date"
              [options]="{ label: 'Data' }"
              (ngModelChange)="checkEventAvailability()"
            ></fullstack-datepicker>
          </div>

          <div class="col-6 mt-1">
            <fullstack-input-select-box
              [options]="{ label: 'Team manager' }"
              [formControl]="slot"
            >
              <ng-container *ngFor="let s of slotList">
                <sb-option
                  [value]="{ account: s.userId, slot: a }"
                  *ngFor="let a of s.slots"
                >
                  {{ a | date : 'short' }} - {{ s.email }}
                </sb-option>
              </ng-container>
            </fullstack-input-select-box>
          </div>

          <div class="col-12 mt-1">
            <fullstack-input-textarea
              [options]="{ label: 'Note', rows: 2 }"
              formControlName="note"
            >
            </fullstack-input-textarea>
          </div>
          <div
            class="col-12 mt-1"
            *ngIf="formAppointment.get('medium').value === 1"
          >
            <fullstack-input-textarea
              [options]="{ label: 'Link riunione', rows: 2 }"
              formControlName="link"
            >
            </fullstack-input-textarea>
          </div>
        </div>

        <div class="text-end">
          <button
            type="button"
            class="btn btn-default rounded m-1"
            (click)="close()"
          >
            Annulla
          </button>

          <button
            [disabled]="!formAppointment.valid"
            type="submit"
            class="btn btn-warning rounded m-1"
          >
            Crea
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class UpdateEventDateModalComponent implements OnInit {
  formAppointment: UntypedFormGroup;
  slot: UntypedFormControl;
  slotList: { email: string; userId: string; slots: string[] }[];
  constructor(
    public dialogRef: MatDialogRef<UpdateEventDateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { event: IRecruitFimmEvents },
    private fb: FormBuilder,
    private authService: AuthServices,
    private eventService: EventService,
    private recruitEventService: RecruitFimmEventsApiService
  ) {
    this.formAppointment = this.fb.group({
      contact: new UntypedFormControl('', []),
      candidate: new UntypedFormControl('', []),
      start: new UntypedFormControl(this.data.event.start, []),
      end: new UntypedFormControl(this.data.event.end, []),
      address: new UntypedFormControl(
        { value: this.data.event.address, disabled: true },
        []
      ),
      user: new UntypedFormControl('', []),
      note: new UntypedFormControl('', []),
      date: new UntypedFormControl(this.data.event.start, []),
      medium: new UntypedFormControl(
        { value: this.data.event.medium, disabled: true },
        []
      ),
      link: new UntypedFormControl(this.data.event.connectionInfo, []),
    });
    this.slot = new UntypedFormControl({}, []);
  }

  ngOnInit(): void {
    this.checkEventAvailability();
  }
  checkEventAvailability() {
    const eventForm = cloneDeep(this.formAppointment.value);
    if (eventForm.date) {
      const request: Partial<IEvent> = {
        start: dayjs(eventForm.date).startOf('day').toDate(),
        end: dayjs(eventForm.date).endOf('day').toDate(),
        address: this.data.event.address,
      };
      this.authService
        .user()
        .pipe(
          switchMap((user) => {
            if (user.roles.includes(RecruitFimmRoles.CC_MANAGER)) {
              return this.eventService.checkEventAvailability(request).pipe(
                concatAll(),
                filter(
                  (slot: {
                    email: string;
                    userId: string;
                    slots: string[];
                  }) => {
                    let user = null;
                    if (typeof this.data.event.user === 'string') {
                      user = this.data.event.user;
                    } else {
                      user = this.data.event.user.id;
                    }
                    return slot?.userId == user;
                  }
                ),
                toArray()
              );
            } else {
              return this.eventService.checkEventAvailability(request).pipe(
                concatAll(),
                filter(
                  (slot: {
                    email: string;
                    userId: string;
                    slots: string[];
                  }) => {
                    let user = null;
                    if (typeof this.data.event.user === 'string') {
                      user = this.data.event.user;
                    } else {
                      user = this.data.event.user.id;
                    }
                    return slot?.userId == user;
                  }
                ),
                toArray()
              );
            }
          })
        )
        .subscribe((res) => (this.slotList = res));
    }
  }
  save() {
    const slot: { slot } = this.slot.value;
    const start = dayjs(slot.slot).toDate();
    const end = dayjs(slot.slot).add(1, 'hour').toDate();
    const contact =
      typeof this.data.event.contact === 'string'
        ? this.data.event.contact
        : this.data.event.contact._id;
    const user =
      typeof this.data.event.user === 'string'
        ? this.data.event.user
        : this.data.event.user.id;
    this.recruitEventService
      .updateEventDate(this.data.event._id, {
        contact: contact,
        user: user,
        start,
        end,
        connectionInfo: this.data.event?.connectionInfo,
      })
      .subscribe();
  }
  close() {
    this.dialogRef.close();
  }
}
