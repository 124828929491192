import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Component({
  selector: 'fullstack-input-multiple-value',
  templateUrl: './input-multiple-value.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMultipleValueComponent),
      multi: true,
    },
  ],
})
export class InputMultipleValueComponent extends FormElement {
  @Input() options: { label: string; type: string };
  @Input() defaultValueIndex;
  @Output() defaultValueIndexChange = new EventEmitter();

  addItem() {
    this.value.push('');
  }

  removeItem(index) {
    if (this.value.length > 1) {
      this.value.splice(index, 1);

      if (index === this.defaultValueIndex) this.defaultValueIndex = undefined;
    }
  }

  writeValue(value) {
    if (!value) {
      this.value = [''];
    } else {
      this.value = value;
    }
  }

  selectFavourite(index) {
    this.defaultValueIndex = index;
    this.defaultValueIndexChange.emit(index);
  }

  onInputChange(val, i) {
    this.value[i] = val.target.value;
    this._onChange(this.value);
  }
}
