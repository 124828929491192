import { IContactIndividual, IEvent, IUser } from 'src/libs/shared/common/src';
import { IBaseCandidate } from '../candidates';

export default interface IRecruitFimmEvents extends IEvent {
  candidate: string;
  userObj?: IUser;
  link?: string;
  cache?: {
    contact: IContactIndividual;
    candidate: IBaseCandidate;
  };
}

export interface IUpdateEvent {
  contact: string;
  user: string;
  start: Date;
  end: Date;
  connectionInfo?: string;
}

export enum RecruitFimmEventType {
  APPUNTAMENTO = 21,
  SECONDO_APPUNTAMENTO = 22,
}
export const RecruitFimmEventTypeLabels = {
  21: 'Appuntamento',
  22: 'Secondo appuntamento',
};

export enum RecruitFimmEventStateEnum {
  FISSATO = 1,
  ANNULLATO = 3, //DONT CHANGE uguale a FIMM
}

export const RecruitFimmEventStateLabels = {
  1: 'Fissato',
  3: 'Annullato',
};

export const RecruitFimmMediumLabels = {
  0: 'In presenza',
  1: 'Online',
};
