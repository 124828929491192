import * as dayjs from 'dayjs';

export const closeToDate = (dates: Date[]): Date => {
  const parsed = dates.map((date) => dayjs(date));
  const difference = parsed.map((date) => {
    return {
      date,
      difference: date.diff(undefined, 'day'),
    };
  });
  const theLowest = difference.reduce((acc, cur) => {
    return acc < cur.difference ? acc : cur.difference;
  }, difference[0].difference);
  return difference.find((i) => i.difference === theLowest).date.toDate();
};

export const IsBeetween = (start: Date, rangeStart: Date, rangeEnd: Date, unit: dayjs.OpUnitType) => {
  const _start = dayjs(start);
  const _rangeStart = dayjs(rangeStart);
  const _rangeEnd = dayjs(rangeEnd);
  const After = _start.isAfter(_rangeStart, unit);
  const Before = _start.isBefore(_rangeEnd, unit);
  const Same = _start.isSame(_rangeStart, unit) || _start.isSame(_rangeEnd, unit);
  // console.log('**** ', unit);
  // console.log('START ', _start.format());
  // console.log('START REQUEST ', _rangeStart.format());
  // console.log('END REQUEST ', _rangeEnd.format());
  // console.log('After ', After);
  // console.log('Before ', Before);
  // console.log('Same ', Same);
  return (After && Before) || Same;
};
