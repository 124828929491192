export type { IBaseCandidate } from './candidate.base.interface';
export type { CreateCandidate } from './candidate.create.interface';
export * from './candidate.interface';
export type {
  ICandidate,
  ICandidateCompanyProfile,
  ICandidateProfile,
  RecruitAppointment,
} from './candidate.interface';
export * from './cof-profile.interface';
export * from './recruitfimm-profile.interface';
export * from './candidate.search.interface';
