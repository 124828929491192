import type { IAddress } from '../addresses';
import { IContact } from '../contacts';
import { IUser } from '../users';

export interface IEvent {
  id?: string;
  _id?: string;
  type?: number;
  avanoaEvent?: string;
  contact?: string | IContact;
  user?: string | IUser;
  start?: Date;
  end?: Date;
  address?: IAddress;
  medium?: number;
  note?: string;
  state?: number;
  attachments?: string[];
  connectionInfo?: string;
  notVisible?: boolean;
  createdAt?: Date;
  insertUser?: string;
  insertUserObj?: IUser;
  graphId?: string;
  parentId?: string;
  isStar?: boolean;
  dispute?: IDispute;
  syncedFromOutlookAt?: Date;
  partecipants?: string[];
}

export interface IDispute {
  reason?: number;
  state?: number;
  note?: string;
  accepted?: boolean;
  insertDate?: Date;
  acceptedDate?: Date;
  newEventId?: string;
}

export enum EventMedium {
  Fisico = 1,
  Virtuale = 2,
  Free = 3,
}

export enum EventStateEnum {
  ESEGUITO = 1,
  RIPROGRAMMATO = 2,
  ANNULLATO = 3,
  CONTESTATO = 4,
}

export enum DisputeStateEnum {
  DA_LAVORARE = 1,
  IN_VERIFICA = 2,
  DA_RICHIAMARE = 3,
  KO = 4,
  APP_DA_FISSARE = 5,
  APP_FISSATO = 6,
  RICHIAMA_CLIENTE = 7,
}

export enum EventType {
  PERSONALE = 17,
  SOPRALLUOGO = 1,
  VISITA = 2,
  INCONTRO = 9,
}

export const eventStateLabels = [
  { label: 'Eseguito', value: EventStateEnum.ESEGUITO },
  { label: 'Riprogrammato', value: EventStateEnum.RIPROGRAMMATO },
  { label: 'Annullato', value: EventStateEnum.ANNULLATO },
];
