import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../../../environments/environment';
import { InterviewComponent } from './interview.component';
import { SharedModule } from '../../shared.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InterviewRoutingModule } from './interview-routing.module';
import { PipesModule } from '../../pipes/pipes.module';
import { SharedCoreModule } from 'src/libs/fe/core-ng/src/lib/shared-core.module';
import { AppuntamentoModule } from 'src/libs/fe/core-ng/src/lib/features';
import { ServicesModule } from 'src/app/services/services.module';
@NgModule({
  declarations: [InterviewComponent],
  imports: [
    CommonModule,
    SharedModule,
    SharedCoreModule,
    InterviewRoutingModule,
    ServicesModule,
    AppuntamentoModule.forRoot({
      eventsEndpoint: `${environment.apiGatewayURL}/events`,
    }),
    ConfirmDialogModule,
    PipesModule,
  ],
  exports: [RouterModule],
})
export class InterviewModule {}
