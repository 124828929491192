import { IGroupData } from './group-data.interface';

export interface IGroup {
  object?: string;
  id: string;
  name?: string;
  // FIXME: describe roles properly.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roles: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: IGroupData;
}
