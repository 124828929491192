import { Pipe, PipeTransform } from '@angular/core';
import { concatAll, filter, map, Observable } from 'rxjs';
import { RecruitFimmUserCacheService } from '../services/recruitfimm-user-cache.service';

@Pipe({
  name: 'nameById',
})
export class NameByIdPipe implements PipeTransform {
  constructor(private cofUsersService: RecruitFimmUserCacheService) {}
  transform(value: string): Observable<string> {
    return this.cofUsersService.getUsers().pipe(
      concatAll(),
      filter((u) => u.id === value),
      map((u) => `${u.firstName} ${u.lastName}`)
    );
  }
}
