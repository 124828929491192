import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'renovars-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberComponent {
  @Input() value: number;
}
