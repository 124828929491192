import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Component({
  selector: 'fullstack-timepicker',
  templateUrl: './timepicker.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ],
  styles: [
    `
      .timepicker-overlay {
        z-index: 1000 !important;
      }
    `,
  ],
})
export class TimepickerComponent extends FormElement implements OnInit {
  @Input() options: { label?: String; placeholder?: String } = {};

  ngOnInit(): void {}
}
