import { Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFileUploadServices } from './input-file-upload.services';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'fullstack-file-upload',
  templateUrl: './input-file-upload.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileUploadComponent),
      multi: true,
    },
  ],
})
export class InputFileUploadComponent implements ControlValueAccessor, OnInit {
  @ViewChild('fileUpload') fileUpload: FileUpload;

  @Input() options: { label: string };
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() accept = 'image/*';
  @Input() maxFileSize = 20000000;
  @Input() uploadApiUrl: string;
  @Input() storageUrl: string;
  @Input() fileLimit = 3;
  @Input() error: string = null;

  constructor(private inputFileUploadServices: InputFileUploadServices) {}

  files = [];

  propagateChange: any = () => {};

  ngOnInit(): void {}

  uploadHandler(event) {
    this.inputFileUploadServices.uploadFiles(this.uploadApiUrl, event.files).subscribe((filesUploaded) => {
      this.fileUpload.clear();
      if (this.multiple) {
        const newFiles = filesUploaded.filter((file) => file !== null);
        this.files = [...this.files, ...newFiles];
        this.propagateChange(this.files);
      } else {
        if (filesUploaded.length === 1 && filesUploaded[0]) {
          const newFile = filesUploaded[0];
          this.files = [newFile];
          this.propagateChange(newFile);
        }
      }
    });
  }

  removeUploaded(i: number) {
    this.files.splice(i, 1);
    if (this.multiple) {
      this.propagateChange(this.files);
    } else {
      this.propagateChange(null);
    }
  }

  removeSelected(fileToDelete: File) {
    this.fileUpload.files = this.fileUpload.files.filter((file) => {
      return file.name !== fileToDelete.name;
    });
  }

  /* Metodi per ReactiveForm */
  writeValue(value: string[] | string) {
    if (value) {
      this.files = Array.isArray(value) ? [...value] : [value];
    } else {
      this.files = [];
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {}
}
