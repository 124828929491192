import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getLabel' })
export class GetLabelPipe implements PipeTransform {
  transform(value: number, list: Array<any>, customField: string) {
    if (list) {
      const elem = list.find((d) => d.value == value);

      return customField ? elem[customField] : elem?.label;
    }

    return value;
  }
}
