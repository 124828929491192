<div class="mx-2 justify-content-between align-items-end">
  <div class="col-12">
    <nav class="page-navbar">
      <ul class="page-navbar__nav">
        <li *ngFor="let t of tabs" class="page-navbar__item">
          <span *ngIf="!t.url" class="page-navbar__link" [ngClass]="{ disabled: t.disabled || !t.url }">{{
            t.label
          }}</span>
          <a
            *ngIf="t.url"
            [routerLinkActive]="'is-active'"
            [routerLink]="t.url"
            queryParamsHandling="preserve"
            class="page-navbar__link"
            >{{ t.label }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</div>
