import { Component, OnInit, ViewChild, ElementRef, ContentChild } from '@angular/core';
import { TimelineItemsDirective } from './timeline.directives';

@Component({
  selector: 'fullstack-timeline',
  templateUrl: './timeline.component.html',
})
export class TimelineComponent implements OnInit {
  @ViewChild('sidetimeline') sideTimelineElem: ElementRef;
  @ContentChild(TimelineItemsDirective) itemsTemplate: TimelineItemsDirective;

  constructor() {}

  ngOnInit(): void {}

  public timelineToggle() {
    this.sideTimelineElem.nativeElement.classList.toggle('d-none');
  }
}
