import { Observable, shareReplay } from 'rxjs';

const _cache: { [key: string]: Observable<any> } = {};

export enum CacheEnum {
  SOURCE_CACHE = 'source_cache',
  DOMAIN_VALUES_CACHE = 'domain_values_cache',
  AUTH_USER_CACHE = 'auth_user_cache',
  USER_REGISTRATION = 'user_registration',
}

export function cacheResponse<T>(
  key: string,
  observable: Observable<any>
): Observable<T> {
  if (!_cache[key]) {
    _cache[key] = observable.pipe(shareReplay(1));
  }
  return _cache[key];
}
