import { Pipe, PipeTransform } from '@angular/core';
import { ISource } from 'src/libs/shared/common/src';
import { SourcesServices } from 'src/libs/fe/core-ng/src/lib/services/sources.service';
import { map } from 'rxjs/operators';

@Pipe({ name: 'sourceType' })
export class SourceTypePipe implements PipeTransform {
  constructor(private sourcesService: SourcesServices) {}

  transform(value: number) {
    return this.sourcesService.list().pipe(
      map((sourceList) => {
        const source = sourceList ? sourceList.find((s) => s.code == value) : null;
        return source ? source.type : '-';
      })
    );
  }
}
@Pipe({ name: 'sourceLabel' })
export class SourceLabelPipe implements PipeTransform {
  constructor(private sourcesService: SourcesServices) {}

  transform(value: number) {
    return this.sourcesService.list().pipe(
      map((sourceList: ISource[]) => {
        const source = sourceList ? sourceList.find((s) => s.code == value) : null;
        return source ? source.label : '-';
      })
    );
  }
}

@Pipe({ name: 'sourceCampaign' })
export class SourceCampaignPipe implements PipeTransform {
  constructor(private sourcesService: SourcesServices) {}

  transform(value: number) {

    return this.sourcesService.list().pipe(
      map((sourceList: ISource[]) => {
        const source = sourceList ? sourceList.find((s) => s.code == value) : null;
        return source ? source.campaign : '-';
      })
    );
  }
}
