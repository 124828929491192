import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from 'src/libs/shared/common/src';
import { environment } from '../../environments/environment';

@Pipe({ name: 'getRoles' })
export class GetRolesPipe implements PipeTransform {
  transform(user: IUser) {
    if (user && user.registrations) {
      const reg = user.registrations.find((r) => r.applicationId == environment.applicationId);
      const roles = reg.roles.filter((r) => r.startsWith('is_'));

      return roles;
    }
  }
}
