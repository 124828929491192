import { IAddress } from '../addresses';
import { ISite } from './site.interface';

export class CreateSiteDto implements Omit<ISite, "_id"> {
  phone: string;
  bcCode: string;
  address: IAddress;
  name: string;
  description: string;
  area: number;
}
