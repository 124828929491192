import { IDomainValue } from 'src/libs/shared/common/src';

export enum SourceTypeDomainValueType {
  CAPTIVE = 1,
  PROSPECT = 2,
}

export enum SourceCampaignDomainValueType {
  DIRETTA = 1,
  SEGNALAZIONE = 2,
  FACEBOOK = 3,
  GOOGLE = 4,
  ORGANICA = 5,
  DEM = 6,
  CALLCENTER = 7,
  NUMEROVERDE = 8,
  RADIOGLOBO = 9,
  FIMM = 10,
  FIMM_TEST_VENDITA = 16,
  FR = 11,
}

export enum EcCampaignEnum {
  DIRETTA_VENDITA = 'Diretta vendita',
  DIRETTA_ACQUISTO = 'Diretta acquisto',
}

export enum SourceGroupType {
  VENDITA = 1,
  ACQUISTO = 2,
}

export const propertyProjectStateLabels = [
  { label: 'Vendita', value: SourceGroupType.VENDITA },
  { label: 'Acquisto', value: SourceGroupType.ACQUISTO },
];

export interface ISource {
  id?: string;
  type?: IDomainValue<SourceTypeDomainValueType> | number;
  code?: number;
  label?: string;
  description?: string;
  campaign?: string;
  isCampaignActive?: boolean;
  position?: number;
  group?: number;
}
