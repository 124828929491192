import { ITrackingData } from '../interfaces';

export function createTrackingBody(trackingUrl: string): ITrackingData {
  if (trackingUrl && trackingUrl !== 'self') {
    const trackingParams = new URLSearchParams(trackingUrl.substring(trackingUrl.lastIndexOf('?') + 1));
    return {
      gclid: trackingParams.get('gclid'),
      utmCampaign: trackingParams.get('utm_campaign'),
      utmContent: trackingParams.get('utm_content'),
      utmMedium: trackingParams.get('utm_medium'),
      utmSource: trackingParams.get('utm_source'),
      utmTerm: trackingParams.get('utm_term'),
      utmId: trackingParams.get('utm_id'),
      rawValue: trackingUrl,
    };
  }
}
