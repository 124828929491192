import type { IAddress } from 'src/libs/shared/common/src';

export async function geocodeByCoords(
  httpService,
  coords: number[],
  apiKey /*AIzaSyDQeZpo_tHnSfT9clnpOByVEXPH9KLPhdk*/
): Promise<IAddress> {
  const res = await httpService
    .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.join(',')}&key=${apiKey}`)
    .toPromise();

  if (res.status != 200) {
    throw new Error('Gmaps call error');
  }

  const jsonRes = res.data;
  const results = jsonRes.results;

  let addressObj;

  if (results && results.length > 0) {
    const result = results[0];
    let country = '';
    let region = '';
    let locality = '';
    let province = '';
    let route = '';
    let street_number = '';
    let postalCode = '';
    let formatted_address = '';
    let coords = [];
    result.address_components.forEach((address) => {
      if (address.types.indexOf('country') !== -1) {
        country = address.long_name;
      }
      if (address.types.indexOf('administrative_area_level_1') !== -1) {
        region = address.long_name;
      }
      if (address.types.indexOf('locality') !== -1) {
        locality = address.long_name;
      }
      if (address.types.indexOf('administrative_area_level_2') !== -1) {
        province = address.short_name;
      }
      if (address.types.indexOf('route') !== -1) {
        route = address.long_name;
      }
      if (address.types.indexOf('street_number') !== -1) {
        street_number = address.long_name;
      }
      if (address.types.indexOf('postal_code') !== -1) {
        postalCode = address.long_name;
      }
    });
    formatted_address = result.formatted_address;
    coords = [result.geometry.location.lng, result.geometry.location.lat];
    addressObj = {
      country,
      region,
      locality,
      province,
      route,
      street_number,
      postalCode,
      formatted_address,
      coords,
    };
  }

  return addressObj;
}
