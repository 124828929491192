import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUrlResponse } from 'src/libs/shared/common/src';
import { Observable } from 'rxjs';
import { AppConfigService } from '../features';

@Injectable()
export class SchedulingServices {
  constructor(private http: HttpClient, private appConfigService: AppConfigService) {}

  signUp(userId: string, successUrl: string, errorUrl: string, redirectUrl: string): Observable<any> {
    return this.http.get<IUrlResponse>(
      `${this.appConfigService.getApiGatewayURL()}/scheduling/signup?userId=${userId}&successUrl=${encodeURIComponent(
        successUrl
      )}&errorUrl=${encodeURIComponent(errorUrl)}&redirectUrl=${encodeURIComponent(redirectUrl)}`
    );
  }

  signOut(userId: string): Observable<any> {
    return this.http.get<IUrlResponse>(
      `${this.appConfigService.getApiGatewayURL()}/scheduling/signout?userId=${userId}`
    );
  }
}
