export interface RecruitFimmDashboard {
  CV_TOTALI: number;
  CV_DA_CHIAMARE: number;
  CV_CONTATTATI: number;
  CV_DA_RICHIAMARE: number;
  CV_IN_KO: number;
  CV_OK_FRBP: number;
  CV_COLLOQUIO_FISSATO: number;
  CV_STANDBY: number;
  CV_DOCUMENTAZIONE_INVIATA: number;
  CV_IN_TRATTATIVA: number;
  CV_IN_GESTIONE: number;
  CV_PREPARAZIONE_CONTRATTO: number;
  CV_ONBOARDING: number;
  CV_CONFERMATI: number;
}

export const EmptyRecruitFimmDashboard = {
  CV_TOTALI: 0,
  CV_DA_CHIAMARE: 0,
  CV_CONTATTATI: 0,
  CV_DA_RICHIAMARE: 0,
  CV_IN_KO: 0,
  CV_OK_FRBP: 0,
  CV_COLLOQUIO_FISSATO: 0,
  CV_STANDBY: 0,
  CV_DOCUMENTAZIONE_INVIATA: 0,
  CV_IN_TRATTATIVA: 0,
  CV_IN_GESTIONE: 0,
  CV_PREPARAZIONE_CONTRATTO: 0,
  CV_ONBOARDING: 0,
  CV_CONFERMATI: 0,
};

export const RecruitFimmDashboardLabels = {
  CV_TOTALI: 'Cv Totali',
  CV_DA_CHIAMARE: 'Cv Da Chiamare',
  CV_CONTATTATI: 'Cv Contattati',
  CV_DA_RICHIAMARE: 'Cv Da Richiamare',
  CV_IN_KO: 'Cv In Ko',
  CV_OK_FRBP: 'Cv FRBPCF',
  CV_COLLOQUIO_FISSATO: 'Ok Colloquio Fissato',
  CV_STANDBY: 'Stand by',
  CV_DOCUMENTAZIONE_INVIATA: 'Bozza Documentazione Inviata',
  CV_IN_TRATTATIVA: 'In Trattativa/OK 2° Colloquio',
  CV_IN_GESTIONE: 'Cv in gestione',
  CV_PREPARAZIONE_CONTRATTO: 'In Preparazione Crontratto',
  CV_ONBOARDING: 'Ok Onboarding',
  CV_CONFERMATI: 'Confermati',
};
export const RecruitFimmDashboardColor = {
  CV_TOTALI: '#ABC270', //emerald
  CV_DA_CHIAMARE: '#A6D1E6', //turquoise
  CV_CONTATTATI: '#B4D2BA', //turquoise chiaro
  CV_DA_RICHIAMARE: '#DCE2AA', //gialloverde desaturato
  CV_IN_KO: '#DEB6AB', //marroncino
  CV_KO: '#DEB6BA', //marroncino2
  CV_OK_FRBP: '#FDA762',
  CV_COLLOQUIO_FISSATO: '#98D9C2', //turquoise chiera
  CV_STANDBY: '#98D9A3',
  CV_DOCUMENTAZIONE_INVIATA: '#8EC3B0', //verde scuro
  CV_IN_TRATTATIVA: '#F5F0BB', //giallo canarino
  CV_IN_GESTIONE: '#85C0BB', //giallo canarino
  CV_PREPARAZIONE_CONTRATTO: '#F5F5BB',
  CV_ONBOARDING: '#5EEB5B', //verde acido
  CV_CONFERMATI: '#FDA769',
};

export interface IDashboardRequest {
  createdAt: {
    start: Date;
    end: Date;
  };
  network?: string;
  site?: string;
  area?: string;
}

export interface MarketingResponseDTO {
  source: string;
  total: number;
  ok: number;
  ko: number;
  ok_secondo: number;
  ko_secondo: number;
}
