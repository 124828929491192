import { DataSource } from '@angular/cdk/collections';

export interface IAccordionTableElement {
  [column: string]:
    | {
        header: string;
        value: any;
      }
    | DataSource<IAccordionTableSubElement[]>;

  dataSource: DataSource<IAccordionTableSubElement[]>;
}

export interface IAccordionTableSubElement {
  columns: string[];

  [column: string]: any | string[];
}
