import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import locale from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  AppConfigModule,
  FileManagerModule,
  HttpErrorInterceptor,
  SetHeaderInterceptor,
} from 'src/libs/fe/core-ng/src';
import { SpinnerInterceptor } from 'src/libs/fe/ui-ng/src';
import * as moment from 'moment';
import 'moment/locale/it';
import { MessageService } from 'primeng/api';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { SharedComponentsModule } from './components/components.module';
import { FeauturesModule } from './features/features.module';
import { RecruitFimmRoles } from '@renovars/common/recruit';
registerLocaleData(locale);

moment.locale('it');

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FeauturesModule,
    SharedComponentsModule,
    AppConfigModule.forRoot({
      apiGatewayURL: environment.apiGatewayURL,
      applicationId: environment.applicationId,
      redirectUrl: environment.redirectUrl,
      apiProxiedUrl: environment.apiGatewayURL,
    }),
    FileManagerModule.forRoot({
      endpoint: environment.apiGatewayURL,
      roles: RecruitFimmRoles,
    }),
  ],
  providers: [
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: SetHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'it-IT' },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
