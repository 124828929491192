<div class="row mb-3">
  <div class="col-12">
    <div class="card">
      <div class="card-header border-bottom pb-2">
        <div class="row align-items-center">
          <div class="col">
            <input
              class="form-control"
              type="text"
              [ngModel]="value?.title"
              (ngModelChange)="_onChange((value.title = $event))"
              [disabled]="options?.disabled"
              placeholder="Titolo"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <p class="tx-15">
          <textarea
            class="form-control"
            type="text"
            [ngModel]="value?.description"
            (ngModelChange)="_onChange((value.description = $event))"
            [disabled]="options?.disabled"
            placeholder="Descrizione"
            rows="4"
          >
          </textarea>
        </p>
      </div>
    </div>
  </div>
</div>
