export interface IFunnel {
  candidate_id: string;
  source: string;
  candidateType: string;
  createdAt: Date;
  createdBy: string;
  assigned: string;
  assignedAt: Date;
  currentOperator: string;
  currentState: string;
  opOldState: string;
  opNewState: string;
  opOldOperator: string;
  opNewOperator: string;
  operationDate: Date;
  operationUser: string;
}
