import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IApplication, IUser } from 'src/libs/shared/common/src';
import { AppConfigService } from 'src/libs/fe/core-ng/src/lib/features/app-config';
import { ApplicationsServices } from 'src/libs/fe/core-ng/src/lib/services';
import { UsersService } from 'src/libs/fe/core-ng/src/lib/services/users.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'facile-anagrafica-upsert',
  template: `
    <form [formGroup]="form" novalidate>
      <div class="content">
        <fullstack-input-user
          [imageStorePath]="
            apiGatewayURL + '/immobiliare/file-server/download/'
          "
          formControlName="user"
        ></fullstack-input-user>

        <fullstack-input-registrazioni
          *ngIf="id"
          [registrazioni]="user?.registrations"
          [applications]="applications$ | async"
          [applicationsCanAdd]="applications$ | async"
        >
        </fullstack-input-registrazioni>
      </div>
    </form>
  `,
  styles: [],
})
export class UtentiUpsertComponent implements OnInit {
  form = this.fb.group({
    user: new UntypedFormControl(null, []),
  });

  user: IUser;
  id: string = null;
  applications$: Observable<IApplication[]>;
  apiGatewayURL;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private applications: ApplicationsServices,
    private usersService: UsersService,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    this.apiGatewayURL = this.appConfig.getApiGatewayURL();
    this.applications$ = of(null);

    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.usersService.getUser(this.id).subscribe((user) => {
          this.form.patchValue({ user: user });
          this.user = user;
        });
      }
    });
  }

  save() {}
}
