import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IRegistration } from 'src/libs/shared/common/src';

@Component({
  selector: 'fullstack-page-layout',
  templateUrl: './page-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      fullstack-page-layout {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
      }
    `,
  ],
})
export class PageLayoutComponent implements OnInit {
  @Input() registration: IRegistration[] = [];

  constructor() {}

  ngOnInit(): void {}
}
