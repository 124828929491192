export interface FrTurnover {
  direttore: string;
  areaManager: string;
  regionalManager: string;
  storeManager: string;
  created: any[];
  workingStarting: any[];
  workingEnding: any[];
}
export interface TurnoverWithCalculations extends FrTurnover {
  turnover: number;
  turnoverPerc: string;
  startTeam: number;
  newTeam: number;
  endTeam: number;
}
