<label class="card card--form">
  <span class="card-body">
    <span class="label-control">{{ options.label }}</span>

    <p-selectButton
      [options]="buttonOptions"
      [ngModel]="value"
      (ngModelChange)="value = $event; _onChange(value)"
      optionLabel="name"
      optionValue="value"
    ></p-selectButton>
  </span>
</label>
