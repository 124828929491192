const appointmentPurchaseListTemplate = /*html*/ `<fullstack-header *ngIf="!headerLess"></fullstack-header>

  <ng-template *ngIf="!headerLess" header-body>
    <h1 class="tx-title-1">Appuntamenti</h1>
    <!-- <h2 class="tx-subtitle mb-0"></h2> -->
  </ng-template>

<fullstack-datatable
    [options]="{lazy : true}"
    (lazyLoad)="onLazyLoad($event)"
    [list]="eventsList?.data"
    [totalRecords]="eventsList?.total"
    (selected)="selected = $event; defineContextMenu()"
    [cmItems]="cmItems"
  >
    <div t-header>
      <div t-column [width]="'250px'">
        <ng-template t-col-title>Nominativo</ng-template>
        <fullstack-input-select-box
          *t-filter
          [options]="{autocomplete : true}"
          ngModel
          (ngModelChange)="paginateRequest.form.contactId = $event; search()"
          (inputChange)="searchContact($event)"
        >
          <sb-option [value]="i.id" *ngFor="let i of contacts$ | async"
            >{{ i | contactName}}</sb-option
          >
        </fullstack-input-select-box>
      </div>
      <div t-column>
      <ng-template t-col-title>Codice Immobile</ng-template>
      <fullstack-input-box
        *t-filter
        (keyup.enter)="search()"
        [(ngModel)]="paginateRequest.form.phone"
      >
      </fullstack-input-box>
    </div>
      <div t-column>
        <ng-template t-col-title>Data inserimento</ng-template>
        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.insertDateRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column>
        <ng-template t-col-title>Indirizzo</ng-template>
        <fullstack-input-box
          *t-filter
          (keyup.enter)="search()"
          [(ngModel)]="paginateRequest.form.address"
        >
        </fullstack-input-box>
      </div>
      <div t-column>
        <ng-template t-col-title>Data inizio</ng-template>
        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.eventStartRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column>
        <ng-template t-col-title>Data fine</ng-template>
        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.eventEndRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column>
        <ng-template t-col-title>Agente</ng-template>
        <fullstack-input-select-box
          *t-filter
          [ngModel]="paginateRequest.form.userId"
          (ngModelChange)="paginateRequest.form.userId = $event; search()"
        >
          <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of usersList$ | async"
            >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
          >
        </fullstack-input-select-box>
      </div>
      <div t-column>
        <ng-template t-col-title>Stato</ng-template>
      </div>
      <div t-column>
        <ng-template t-col-title>Creato Da</ng-template>
        <fullstack-input-select-box
          *t-filter
          [ngModel]="paginateRequest.form.insertUserId"
          (ngModelChange)="paginateRequest.form.insertUserId = $event; search()"
        >
          <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of usersList$ | async"
            >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
          >
        </fullstack-input-select-box>
      </div>
      <div t-column>
        <ng-template t-col-title>Data Creazione</ng-template>
        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.insertDateRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
    </div>

    <ng-template t-body let-row>
      <td>{{row.projectSaleObj?.data?.contactObj | contactName}}</td>
      <td>{{row.projectSaleObj?.data?.propertyObj?.name || '-'}}</td>
      <td>{{ row?.createdAt | date : 'short'}}</td>
      <td>{{row?.address?.formatted_address}}</td>
      <td>{{ row?.start | date : 'short'}}</td>
      <td>{{ row?.end | date : 'short'}}</td>
      <td>{{ row?.user?.firstName}} {{ row?.user?.lastName}}</td>
      <td>{{ (row?.state | domainValue: 'event_state' | async) || 'Non esitato' }}</td>
      <td>{{ row?.insertUserObj?.firstName}} {{ row?.insertUserObj?.lastName}}</td>
      <td>{{ row?.createdAt | date: 'short' }}</td>
    </ng-template>
  </fullstack-datatable>`;

export default appointmentPurchaseListTemplate;
