<header class="page-header border-bottom-0">
  <h2 class="tx-title-4 d-flex justify-content-between">
    {{ options?.label }}<i class="fas fa-plus" (click)="!isDisabled && addItem()"></i>
  </h2>
</header>
<label *ngFor="let l of value; let i = index" class="card card--form mh-auto">
  <div class="card--form__close" (click)="removeItem(i)">
    <i class="fas fa-times-circle"></i>
  </div>
  <span class="card-body">
    <span class="label-control d-flex justify-content-between">
      {{ options?.label }}
      <i
        *ngIf="value.length > 1"
        class="fas fa-star"
        [ngClass]="{
          'cl-secondary': defaultValueIndex == i,
          'cl-medium': defaultValueIndex != i
        }"
        (click)="selectFavourite(i)"
      >
      </i>
    </span>
    <input
      class="form-control"
      type="{{ options.type }}"
      [ngModel]="value[i]"
      (change)="onInputChange($event, i)"
      [disabled]="isDisabled"
  /></span>
</label>
