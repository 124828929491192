type Lookup = {
  $lookup: {
    from: string;
    foreignField: string;
    localField: string;
    as: string;
  };
};
type Sort = {
  $sort: {
    [key: string]: 1 | -1;
  };
};
type Unwind = {
  $unwind: `$${string}`;
};
type Match = {
  $match: any;
};
type Count = {
  $count: string;
};
type Subtract = {
  $subtract: [string | number | Date, string | number | Date];
};
type Sum = {
  $sum: [string | number | Date, string | number | Date];
};
type AddFields = {
  $addFields: {
    [key: string]: any;
  };
};
type Aggregate = (Lookup | Sort | Unwind | Match | Count | Subtract | Sum | AddFields)[];

export class FluentAggregate {
  private value: Aggregate;
  constructor() {
    this.value = [];
  }
  private set(value: Aggregate) {
    this.value = value;
  }
  get() {
    return this.value;
  }
  lookup({ from, foreignField, localField, as }: Lookup['$lookup']) {
    this.set([
      ...this.value,
      {
        $lookup: {
          from,
          foreignField,
          localField,
          as,
        },
      } as Lookup,
    ]);
    return this;
  }
  match(matchOp) {
    this.set([
      ...this.value,
      {
        $match: matchOp,
      } as Match,
    ]);
    return this;
  }
  sortAsc(field: string) {
    this.set([
      ...this.value,
      {
        $sort: {
          [field]: 1,
        },
      } as Sort,
    ]);
    return this;
  }
  sortDsc(field: string) {
    this.set([
      ...this.value,
      {
        $sort: {
          [field]: -1,
        },
      } as Sort,
    ]);
    return this;
  }
  unwind(field: string) {
    this.set([
      ...this.value,
      {
        $unwind: '$' + field,
      } as Unwind,
    ]);
    return this;
  }
  count(resultField: string) {
    this.set([
      ...this.value,
      {
        $count: resultField,
      } as Count,
    ]);
    return this;
  }
  subtract(valueA: string | number | Date, valueB: string | number | Date) {
    this.set([
      ...this.value,
      {
        $subtract: [valueA, valueB],
      } as Subtract,
    ]);
    return this;
  }
  sum(valueA: string | number | Date, valueB: string | number | Date) {
    this.set([
      ...this.value,
      {
        $sum: [valueA, valueB],
      } as Sum,
    ]);
    return this;
  }
  addFields(key: string, expression: unknown) {
    this.set([
      ...this.value,
      {
        $addFields: {
          [key]: expression,
        },
      } as AddFields,
    ]);
    return this;
  }
}
