export enum CofRoles {
  ADMIN = 'is_admin',
  CC_MANAGER = 'is_cc_manager',
  HR_OPERATOR = 'is_hr_operator',
  HR_JR = 'is_hr_jr',
  AUTOASSIGN = 'auto_assign',
}
export enum CofRolesLabels {
  'is_admin' = 'Admin',
  'is_hr_operator' = 'Operatore Telefonico',
  'is_cc_manager' = 'CC  Manager',
  'is_hr_jr' = 'Hr Jr',
}

export const CofRolesLevels = [CofRoles.CC_MANAGER];

export function getUpline(roleStart: CofRoles) {
  let roleLevelStartIndex = CofRolesLevels.findIndex((r) => r == roleStart);
  return CofRolesLevels.slice(roleLevelStartIndex);
}
