import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEvent, IRange } from 'src/libs/shared/common/src';
import { RecruitFimmEventType } from 'src/libs/shared/recruit/src';
import IRecruitFimmEvents, {
  IUpdateEvent,
} from 'src/libs/shared/recruit/src/lib/interfaces/events/recruitfimm-events.interface';
import { MessageService } from 'primeng/api';
import { catchError, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecruitFimmEventsApiService {
  env = environment;
  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {}
  updateEventDate(id: string, dto: IUpdateEvent) {
    return this.http.put<IRecruitFimmEvents>(
      `${environment.apiGatewayURL}/events/${id}`,
      dto
    );
  }
  replaceEvent$(oldId: string, event: IRecruitFimmEvents) {
    return this.http
      .post<IRecruitFimmEvents>(
        `${environment.apiGatewayURL}/events/change/${oldId}`,
        event
      )
      .pipe(
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }
  getEventsByContact$(contact: string, type: RecruitFimmEventType) {
    return this.http
      .get<IRecruitFimmEvents[]>(
        `${environment.apiGatewayURL}/events/old?contact=${contact}&type=${type}`
      )
      .pipe(
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }
  createTraining(body: IEvent) {
    return this.http
      .post<IRecruitFimmEvents>(
        `${environment.apiGatewayURL}/events?type=${RecruitFimmEventType.SECONDO_APPUNTAMENTO}`,
        body
      )
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Affiancamento creato',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }
}
