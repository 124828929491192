export interface IDomainValue<T> {
  object?: string;
  id?: string;
  type: T;
  pos: number;
  label: string;
  value?: string;
  code: number;
  group?: IDomainValue<T> | number;
  metadata?: unknown;
}
