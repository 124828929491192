import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormElement } from '../core/utils/form-element';
import { SelectBoxOptionDirective } from './input-select-box.directive';
import { IAddress } from '@renovars/common';
@Component({
  selector: 'fullstack-input-select-box',
  templateUrl: './input-select-box.component.html',
  styleUrls: ['./input-select-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectBoxComponent),
      multi: true,
    },
  ],
})
export class InputSelectBoxComponent
  extends FormElement
  implements OnInit, OnChanges, AfterContentInit, OnDestroy, AfterViewInit
{
  constructor(private eRef: ElementRef) {
    super();
  }

  @Input() options: {
    label: string;
    placeholder: string;
    autocomplete: boolean;
  };
  @ContentChildren(SelectBoxOptionDirective)
  selectOptions: QueryList<SelectBoxOptionDirective>;
  @ViewChild('select') selectElem: ElementRef;
  selectOptionsSubscription: Subscription;

  filteredSelectOptions: SelectBoxOptionDirective[];
  labelsList: string[];
  selectedIndex: number;
  inputString: string;
  isActive = false;
  @Input() isSelectDisabled: boolean;
  @Input() isAddress = false;
  @Input() error: string = null;
  @Output() inputChange = new EventEmitter();
  inputChangeSubject = new Subject();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit(): void {
    if (this.isSelectDisabled) {
      this.setDisabledState(this.isSelectDisabled);
    }
    this.inputChangeSubject
      .pipe(debounceTime(1000))
      .subscribe((res) => this.inputChange.emit(this.inputString));
  }

  ngAfterViewInit(): void {
    /* return new bootstrap.Dropdown(this.selectElem.nativeElement, {
      popperConfig: {
        strategy: 'fixed',
      },
    }); */
  }

  ngAfterContentInit(): void {
    this.initSelect();
    this.selectOptionsSubscription = this.selectOptions.changes.subscribe(
      (changes) => {
        this.initSelect();
      }
    );
  }

  writeValue(v) {
    super.writeValue(v);

    if (this.selectOptions) {
      this.value = v;
      if (!this.value) {
        this.selectedIndex = undefined;
        this.inputString = undefined;
        this.initSelect();
      }

      this.selectOptions.notifyOnChanges();
    }
  }

  initSelect() {
    this.filteredSelectOptions = this.selectOptions.toArray();
    if (this.value) {
      if (this.isAddress) {
        this.selectItem(this.findItemIndexForAddress(this.value), false);
      }
      this.selectItem(this.findItemIndex(this.value), false);
    }
  }

  filterOptions() {
    if (!this.options?.autocomplete) {
      if (!this.inputString || this.inputString == '') {
        this.filteredSelectOptions = this.selectOptions.toArray();
        this.closeDropdown();
        return;
      }

      this.filteredSelectOptions = this.selectOptions.filter(
        (so) =>
          so.label &&
          so.label.toLowerCase().indexOf(this.inputString.toLowerCase()) != -1
      );
    } else {
      this.inputChangeSubject.next(null);
    }
  }

  getValue(option: SelectBoxOptionDirective) {
    if (option)
      return option.key ? _.get(option.value, option.key) : option.value;
  }

  findItemIndex(value: string) {
    if (this.filteredSelectOptions && this.filteredSelectOptions.length) {
      return this.filteredSelectOptions.findIndex((l) => {
        if (l.key) {
          return _.get(l.value, l.key) == value;
        }
        return l.value == value;
      });
    }
  }
  findItemIndexForAddress(item: IAddress) {
    if (this.filteredSelectOptions && this.filteredSelectOptions.length) {
      return this.filteredSelectOptions.findIndex((l) => {
        const cond =
          l.value.coords[0] === item.coords[0] &&
          l.value.coords[1] === item.coords[1];
        return cond;
      });
    }
  }

  openDropdown() {
    this.isActive = true;
  }

  closeDropdown() {
    this.isActive = false;
  }

  toggleDropdown() {
    this.isActive ? this.closeDropdown() : this.openDropdown();
  }

  selectItem(i: number | undefined, notifyChange = true) {
    let selectedOption: SelectBoxOptionDirective;
    if (i == undefined) {
      this.selectedIndex = undefined;
      this.inputString = undefined;
      this.filterOptions();
      if (this._onChange && notifyChange) this._onChange(null);
    } else {
      selectedOption = this.filteredSelectOptions[i];
      if (selectedOption) this.inputString = selectedOption.label;
      if (this._onChange && notifyChange)
        this._onChange(this.getValue(selectedOption));
    }

    this.closeDropdown();
  }

  ngOnDestroy() {}
}
