import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IApplication, IRegistration } from 'src/libs/shared/common/src';
import { MenuItem } from 'primeng/api';
import { MatDialog } from '@angular/material/dialog';
import { ModalInputRegistrazioneComponent } from './modal-input-registrazione.component';

@Component({
  selector: 'fullstack-input-registrazioni',
  templateUrl: './input-registrazioni.component.html',
})
export class InputRegistrazioniComponent implements OnInit, OnChanges {
  @Input() applications: IApplication[];
  @Input() registrazioni: IRegistration[];
  @Input() applicationsCanAdd: IApplication[];

  selected: IRegistration;
  applicationsRemainToAdd: IApplication[];

  cmItems: MenuItem[] = [
    {
      label: 'Modifica',
      command: () => {
        this.update();
      },
    },
    {
      label: 'Elimina',
      command: () => {
        this.delete();
      },
    },
  ];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.applicationsCanAdd && this.registrazioni) {
      this.applicationsRemainToAdd = this.applicationsCanAdd.filter((app) => {
        if (!this.registrazioni.find((registrazione) => registrazione.applicationId === app.id)) {
          return app;
        }
      });
    }
  }

  update() {
    this.openModalRegistrazione(this.selected);
  }

  delete() {}

  openModalRegistrazione(registrazione) {
    this.dialog.open(ModalInputRegistrazioneComponent, {
      minWidth: '600px',
      minHeight: '300px',
      data: {
        registrazione,
        applications: registrazione
          ? this.applications.filter((app) => app.id === registrazione.applicationId)
          : this.applicationsRemainToAdd,
      },
    });
  }
}
