import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactsServices } from 'src/libs/fe/core-ng/src/lib/services';
import { tap } from 'rxjs';

@Component({
  selector: 'contact-upsert',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Contatti</h1>
      <h2 class="tx-subtitle mb-0">Creazione/Modifica</h2>
    </ng-template>

    <form [formGroup]="form" novalidate>
      <div class="content">
        <fullstack-contact-form formControlName="contact"> </fullstack-contact-form>
        <div class="row mt-4">
          <div class="col-sm-12 text-end">
            <button type="button" class="btn btn-primary rounded me-4" [routerLink]="['/contacts']">
              Annulla
            </button>
            <button
              type="button"
              class="btn btn-secondary rounded"
              (click)="save()"
              [disabled]="!form.valid || form.get('contact').value === null"
            >
              Salva
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
})
export class ContactUpsertComponent implements OnInit {
  form = this.fb.group({
    contact: new UntypedFormControl(null),
  });

  id: string = null;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private contactsServices: ContactsServices,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.contactsServices.getContact(this.id).subscribe((res) => {
          if (res != null) this.form.patchValue({ contact: res });
        });
      }
    });
  }

  save() {
    if (this.id) {
      this.contactsServices
        .updateContact(this.form.value.contact, this.id)
        .pipe(tap(() => this.router.navigate(['..'], { relativeTo: this.activatedRoute })))
        .subscribe();
    } else {
      this.contactsServices
        .saveContact(this.form.value.contact)
        .pipe(tap(() => this.router.navigate(['..'], { relativeTo: this.activatedRoute })))
        .subscribe();
    }
  }
}
