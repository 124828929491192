import { IRegistrationData } from './registration-data.interface';

export interface IRegistration {
  id?: string;
  applicationId: string;
  roles: string[];
  insertInstant?: number;
  lastLoginInstant?: number;
  lastUpdateInstant?: number;
  name?: string;
  usernameStatus?: string;
  verified?: boolean;
  data?: IRegistrationData;
}
