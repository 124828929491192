import { Directive, Input, TemplateRef } from '@angular/core';
@Directive({ selector: '[timeline-marker]' })
export class TimelineMarkerDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: '[timeline-content]' })
export class TimelineContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: '[timeline-opposite]' })
export class TimelineOppositeDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
