<table cdk-table [dataSource]="dataSource" multiTemplateDataRows>
  <ng-container cdkColumnDef="{{ column }}" *ngFor="let column of columns">
    <th cdk-header-cell *cdkHeaderCellDef class="header-row">{{ column }}</th>
    <td cdk-cell *cdkCellDef="let element">{{ element[column] }}</td>
  </ng-container>
  <ng-container cdkColumnDef="expandedDetail">
    <td cdk-cell *cdkCellDef="let element">
      <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <table cdk-table [dataSource]="element.dataSource" class="mat-elevation-z8">
          <ng-container *ngFor="let col of element.columns" [cdkColumnDef]="col">
            <td cdk-cell *cdkCellDef="let element">{{ element[col] }}</td>
          </ng-container>

          <tr cdk-row *cdkRowDef="let row; columns: element.columns"></tr>
        </table>
      </div>
    </td>
  </ng-container>

  <tr cdk-header-row *cdkHeaderRowDef="columns"></tr>
  <tr
    cdk-row
    *cdkRowDef="let element; columns: columns"
    class="element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element"
  ></tr>
  <tr cdk-row *cdkRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
</table>
