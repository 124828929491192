import { Application } from '@fusionauth/typescript-client';

export interface IApplication extends Application {
  object: string;
}

export enum APPLICATION_ID {
  FI = '6307c0f4-5377-40e1-b5fa-2d297aad4806',
  RECRUIT = '885cc26c-8255-4302-9b1e-98127fbd49bd',
  RECRUITFIMM = 'f46c4726-2671-4983-9da6-8b4c9bc92311',
  PORTALE = '4b9875bf-be20-4c19-9e6f-3914d3b1a0d9',
  COF = 'ea668174-6745-4946-a3f6-f4cb4ef5e26c',
}
