import { Component, OnInit } from '@angular/core';
import { ITreeNode, IUser } from 'src/libs/shared/common/src';
import * as _ from 'lodash';
import { NetworkService } from '../../../../../services/network.service';
@Component({
  selector: 'network-summary-page',
  templateUrl: `./summary.component.html`,
  styles: [``],
})
export class NetworkSummaryPageComponent implements OnInit {
  network: ITreeNode<IUser>[];

  constructor(private networkService: NetworkService) {}

  ngOnInit(): void {
    this.networkService.get().subscribe((n) => (this.network = _.cloneDeep(n)));
  }
}
