import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CandidateType } from 'src/libs/shared/recruit/src';
import { SiteService } from 'src/libs/fe/core-ng/src';
import {
  APPLICATION_ID,
  IAppointmentLimit,
  ISite,
  IUser,
  IUserMeta,
} from 'src/libs/shared/common/src';
import { AppConfigService } from 'src/libs/fe/core-ng/src/lib/features/app-config';
import { AuthServices } from 'src/libs/fe/core-ng/src/lib/services';
import { UsersService } from 'src/libs/fe/core-ng/src/lib/services/users.service';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { lastValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { setParentForm } from '../../../../utils';
import {
  UtentiModuleOptions,
  UTENTI_CONFIG_PROVIDER,
} from '../../module.options';
@Component({
  selector: 'utenti-additional-data-form',
  template: `
    <form [formGroup]="form" novalidate>
      <div>
        <div class="row">
          <div class="col-12">
            <header class="page-header">
              <h2 class="tx-title-2">Zona</h2>
            </header>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-12 col-md-12">
            <fullstack-input-address-box
              [options]="{ label: 'Indirizzo sede *' }"
              formControlName="address"
              [withRadius]="true"
            ></fullstack-input-address-box>
          </div>
          <div
            class="col-sm-12 mt-2"
            *ngIf="!utentiModuleOption?.excludeField?.site"
          >
            <fullstack-input-select-box
              [options]="{ label: 'Sede di riferimento' }"
              formControlName="site"
              [isAddress]="true"
            >
              <sb-option [value]="i._id" *ngFor="let i of sites$ | async">{{
                i.name
              }}</sb-option>
            </fullstack-input-select-box>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <header class="page-header border-bottom-0">
              <h2 class="tx-title-3">Descrizione</h2>
            </header>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <fullstack-input-textarea
              [options]="{ label: 'Descrizione utente', rows: 6, cols: 80 }"
              formControlName="description"
            ></fullstack-input-textarea>
          </div>
        </div>
        <!-- <div *permissions="['appointment_config_manager']">
          <div class="row mb-4">
            <div class="col-sm-12">
              <header class="page-header">
                <h2 class="tx-title-3">Appuntamenti</h2>
              </header>
            </div>
          </div>
          <div class="row" formGroupName="appointmentLimit">
            <div class="col-sm-12 col-md-4">
              <fullstack-input-box
                [options]="{ label: 'Settimanali' }"
                formControlName="week"
              ></fullstack-input-box>
            </div>

            <div class="col-sm-12 col-md-4">
              <fullstack-input-box
                [options]="{ label: 'Mensili' }"
                formControlName="month"
              ></fullstack-input-box>
            </div>

            <div class="col-sm-12 col-md-4">
              <fullstack-input-box
                [options]="{ label: 'Annuali' }"
                formControlName="year"
              ></fullstack-input-box>
            </div>
          </div>
        </div> -->
        <div class="row mb-4">
          <div class="col-sm-12">
            <header class="page-header">
              <h2 class="tx-title-3">Network</h2>
            </header>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-4">
            <fullstack-input-select-box formControlName="parent">
              <sb-option
                [key]="'id'"
                [value]="i"
                *ngFor="let i of parentsList$ | async"
                >{{ i.email }}</sb-option
              >
            </fullstack-input-select-box>
          </div>
        </div>

        <!-- <div *ngIf="application === 'recruit'">
          <div class="row mb-4">
            <div class="col-sm-12">
              <header class="page-header">
                <h2 class="tx-title-3">Tipi di candidato</h2>
              </header>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12">
              <fullstack-checkbox-multiple-button [options]="{ label: '' }" formControlName="candidateType">
                <checkbox [label]="'Architetti'" [value]="CandidateType.ARCHITECT"></checkbox>
                <checkbox [label]="'Operai'" [value]="CandidateType.WORKER"></checkbox>
                <checkbox [label]="'Imprese'" [value]="CandidateType.COMPANY"></checkbox>
              </fullstack-checkbox-multiple-button>
            </div>
          </div>
        </div> -->
        <!-- end -->
      </div>

      <!-- <div class="row">
        <div class="col-sm-12 col-md-4">
          <header class="page-header border-bottom-0">
            <h2 class="tx-title-3">Disponibilità</h2>
          </header>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <fullstack-input-check-box
            formControlName="isUnavailable"
            [options]="{ label: 'Non disponibile' }"
          ></fullstack-input-check-box>
          <small style="color: #f44336;" *ngIf="(isUnAvaiableFormValue$ | async) === true"
            >L'utente non è disponibile, gli slot orari verrano ignorati.</small
          >
          <fullstack-day-time-slot formControlName="availabilitySlots"></fullstack-day-time-slot>
        </div>
        <div class="col-4" *ngIf="!utentiModuleOption?.excludeField?.acceptStar">
          <fullstack-input-check-box
            formControlName="acceptStar"
            [options]="{ label: 'Accetta appuntamenti stella' }"
          ></fullstack-input-check-box>
        </div>
      </div> -->
      <!-- <div class="row" *ngIf="!utentiModuleOption?.excludeField?.bc">
        <div class="col-sm-12 col-md-4">
          <header class="page-header border-bottom-0">
            <h2 class="tx-title-3">Business Central</h2>
            <div *ngIf="bcId; else showBC">
              <p class="text-muted">ID: {{ bcId }}</p>
            </div>
            <ng-template #showBC>
              <button class="btn btn-secondary rounded" (click)="activateBC(id)">
                Attiva su Business Central
              </button>
            </ng-template>
          </header>
        </div>
      </div> -->
    </form>
  `,
})
export class UtentiAdditionalDataFrom implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  form: IFormGroup<IUserMeta>;
  fb: IFormBuilder = new UntypedFormBuilder();
  isUnAvaiableFormValue$;
  sites$: Observable<ISite[]>;
  parentsList$: Observable<IUser[]>;
  CandidateType = CandidateType;
  application: string;
  APPLICATION_ID = APPLICATION_ID;
  currentAppId: string;
  id;
  bcId: string;

  constructor(
    private usersService: UsersService,
    private authService: AuthServices,
    @Inject(UTENTI_CONFIG_PROVIDER)
    public utentiModuleOption: UtentiModuleOptions,
    private activatedRoute: ActivatedRoute,
    private appConfigService: AppConfigService,
    private siteService: SiteService
  ) {
    this.form = this.fb.group<IUserMeta>({
      appointmentLimit: this.fb.group<IAppointmentLimit>({
        month: new UntypedFormControl(null, []),
        year: new UntypedFormControl(null, []),
        week: new UntypedFormControl(null, []),
      }),
      address: new UntypedFormControl(null, [Validators.required]),
      parent: new UntypedFormControl(null, []),
      candidateType: new UntypedFormControl([], []),
      availabilitySlots: new UntypedFormControl(null, []),
      isUnavailable: new UntypedFormControl(null, []),
      description: new UntypedFormControl(null, []),
      site: new UntypedFormControl(null, []),
      acceptStar: new UntypedFormControl(null, []),
    });
    this.isUnAvaiableFormValue$ =
      this.form.controls['isUnavailable'].valueChanges;
    this.sites$ = this.siteService.findAll();
  }

  ngOnInit(): void {
    this.currentAppId = this.appConfigService.getApplicationId();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.usersService.getUser(this.id).subscribe((user) => {
          if (user) {
            const filterRole =
              this.utentiModuleOption.service.calculateNetworkPriority(
                user,
                this.appConfigService.getApplicationId()
              );

            this.parentsList$ = this.usersService.getUsersListDownline(
              ['is_admin'],
              filterRole
            );

            setParentForm(this.parentForm, this.form);

            this.usersService
              .getUserMeta(this.id)
              .pipe(
                tap((val) => {
                  if (val) {
                    this.bcId = val.bcId;
                    this.parentForm.patchValue(val);
                  }
                })
              )
              .subscribe();
          }
        });
      }
    });
  }

  activateBC(userId: string) {
    lastValueFrom(this.usersService.activateBC(userId));
  }
}
