import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from 'src/libs/fe/ui-ng/src';
import { ContactsServices } from '../../services/contacts.services';
import { UtilsService } from '../../services/utils.services';
import { DeletePersonalModalComponent } from './components/modals/delete-personal-modal/delete-personal-modal.component';
import { PersonalEventModalComponent } from './components/modals/personal-event-modal/personal-event-modal.component';
import { CalendarioComponent } from './components/pages/list/calendario.component';
import { CalendarioModuleConfig, CALENDARIO_CONFIG_PROVIDER } from './module.options';
import { CalendarioService } from '../../services/calendario.service';
import { PipeLibsModule } from 'src/libs/fe/core-ng/src/lib/pipes/pipe-libs.module';
import { TooltipModule } from 'primeng/tooltip';

const ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: CalendarioComponent,
      },
    ],
  },
];
@NgModule({
  declarations: [CalendarioComponent, PersonalEventModalComponent, DeletePersonalModalComponent],
  imports: [UiModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(ROUTES), PipeLibsModule, TooltipModule],
  exports: [UiModule, PipeLibsModule],
})
export class CalendarioModule {
  static forRoot(config: CalendarioModuleConfig): ModuleWithProviders<CalendarioModule> {
    return {
      ngModule: CalendarioModule,
      providers: [
        {
          provide: CALENDARIO_CONFIG_PROVIDER,
          useValue: config,
        },
        CalendarioService,
        ContactsServices,
        UtilsService,
      ],
    };
  }
}
