import { Injectable } from '@angular/core';
import { RecruitFimmRoles } from 'src/libs/shared/recruit/src';
import { UsersService } from 'src/libs/fe/core-ng/src/lib/services';
import { IUser } from 'src/libs/shared/common/src';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecruitFimmUserCacheService {
  cachedUsers$: Observable<IUser[]>;
  constructor(private usersService: UsersService) {}

  getUsers(): Observable<IUser[]> {
    if (!this.cachedUsers$) {
      this.cachedUsers$ = this.usersService
        .excludeNetworkUsers$([
          RecruitFimmRoles.CC_MANAGER,
          RecruitFimmRoles.HR_OPERATOR,
        ])
        .pipe(shareReplay(1));
    }
    return this.cachedUsers$;
  }
}
