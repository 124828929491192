import { Component, ContentChildren, OnInit, ViewEncapsulation } from '@angular/core';
import { TabDirective } from './tabs.directive';

@Component({
  selector: 'fullstack-tabs',
  templateUrl: './tabs.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TabsComponent implements OnInit {
  @ContentChildren(TabDirective) tabs: Array<TabDirective>;

  constructor() {}

  ngOnInit(): void {}
}
