import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import type { ISite } from 'src/libs/shared/common/src';
import { ConfirmModalComponent } from 'src/libs/fe/ui-ng/src';
import { MenuItem } from 'primeng/api';
import { filter, Observable } from 'rxjs';
import { ISiteOptions, SITE_OPTIONS } from '../../../site.module.options';
import { SiteService } from '../../../site.service';

@Component({
  selector: 'facile-site-list',
  templateUrl: './site-list.component.html',
  styles: [],
})
export class SiteListComponent implements OnInit {
  sites$: Observable<ISite[]>;
  cmItems: MenuItem[] = [
    {
      label: 'Aggiorna',
      command: () => {
        this.gotoDetail();
      },
    },
    {
      label: 'Elimina',
      command: () => {
        this.delete();
      },
    },
  ];
  selected: ISite;

  bcCodeVisible = true;
  phoneVisibile = true;
  areaVisibile = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private siteServices: SiteService,
    private dialog: MatDialog,
    @Inject(SITE_OPTIONS) private options: ISiteOptions
  ) {}

  ngOnInit(): void {
    this.loadData();
  }
  loadData(): void {
    this.sites$ = this.siteServices.findAll();
    this.bcCodeVisible = this.options?.exclude?.bcCode ? false : true;
    this.phoneVisibile = this.options?.exclude?.phone ? false : true;
    this.areaVisibile = this.options?.exclude?.area ? false : true;
  }

  gotoDetail() {
    this.router.navigate([`./detail`], {
      queryParams: { id: this.selected._id },
      relativeTo: this.activatedRoute,
    });
  }

  delete() {
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          text: `Confermi di voler eliminare questo elemento?`,
        },
      })
      .afterClosed()
      .pipe(filter((res) => res && res.confirmed))
      .subscribe(() => {
        this.siteServices.delete(this.selected._id).subscribe(() => this.loadData());
      });
  }
}
