import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputRegistrazioniComponent } from './input-registrazioni.component';
import { InputBoxModule } from '../input-box';
import { DatePickerModule } from '../datepicker';
import { InputAddressBoxModule } from '../input-address-box';
import { InputSelectBoxModule } from '../input-select-box';
import { InputMultipleValueModule } from '../input-multiple-value';
import { InputCheckBoxModule } from '../input-check-box';
import { CheckboxButtonModule } from '../checkbox-button';
import { ModalInputRegistrazioneComponent } from './modal-input-registrazione.component';
import { DatatableModule } from '../datatable';

const COMPONENTS = [InputRegistrazioniComponent, ModalInputRegistrazioneComponent];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    InputBoxModule,
    DatePickerModule,
    InputAddressBoxModule,
    InputCheckBoxModule,
    CheckboxButtonModule,
    InputSelectBoxModule,
    InputMultipleValueModule,
    DatatableModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class InputRegistrazioniModule {}
