import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import type { ISite } from 'src/libs/shared/common/src';
import { of, switchMap } from 'rxjs';
import { SiteService } from '../../../site.service';

@Component({
  selector: 'facile-site-detail',
  template: `
    <ng-template header-body></ng-template>
    <div class="content">
      <form [formGroup]="form" (submit)="save()" novalidate>
        <facile-site-form formControlName="siteForm"></facile-site-form>

        <div class="text-end my-2">
          <button type="submit" class="btn btn-secondary rounded mx-2" (click)="close()">Indietro</button>
          <button type="submit" class="btn btn-secondary rounded">Salva</button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class SiteDetailComponent implements OnInit {
  id;
  form = this.fb.group({
    siteForm: this.fb.control(null, []),
  });

  constructor(
    protected fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private siteService: SiteService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        switchMap((p) => {
          if (p.id) {
            this.id = p.id;
            return this.siteService.findById(p.id);
          } else {
            return of(null);
          }
        })
      )
      .subscribe((v) => {
        if (!v) return;
        this.form.get('siteForm').patchValue(v);
      });
  }

  save() {
    if (this.form.valid) {
      const obj: ISite = this.form.get('siteForm').value;
      if (this.id) {
        this.siteService.update(this.id, obj).subscribe(() => this.close());
      } else {
        this.siteService.save(obj).subscribe(() => this.close());
      }
    }
  }
  close() {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }
}
