import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  IAppointmentSearchDTO,
  IEvent,
  IPaginateRequest,
  IPaginateResponse,
} from 'src/libs/shared/common/src';
import { Observable } from 'rxjs';
import {
  CalendarioModuleConfig,
  CALENDARIO_CONFIG_PROVIDER,
} from '../features/calendario/module.options';

@Injectable()
export class CalendarioService {
  constructor(
    private http: HttpClient,
    @Inject(CALENDARIO_CONFIG_PROVIDER) private config: CalendarioModuleConfig
  ) {}

  search(
    paginateRequest: IPaginateRequest<IAppointmentSearchDTO>,
    filter = true
  ): Observable<IPaginateResponse<IEvent>> {
    const url = filter
      ? `${this.config.eventsEndpoint}/search`
      : `${this.config.eventsEndpoint}/search?filter=false`;
    return this.http.post<any>(url, paginateRequest);
  }
}
