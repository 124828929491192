import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEvent } from '@renovars/common';

@Component({
  selector: 'app-delete-event-modal',
  template: `
    <h3 mat-dialog-title>Annulla Evento</h3>
    <div class="content" mat-dialog-content>
      <div class="row">
        <div class="col-6">
          <div>
            <b>Utente: </b><span>{{ event?.user | nameById | async }}</span>
          </div>
          <div>
            <b>Data: </b><span>{{ event?.start | date : 'longDate' }}</span>
          </div>
          <div>
            <b>Orario: </b
            ><span
              >{{ event?.start | date : 'shortTime' }} -
              {{ event?.end | date : 'shortTime' }}</span
            >
          </div>
          <div>
            <b>Indirizzo: </b
            ><span>{{ event?.address?.formatted_address }}</span>
          </div>
          <div>
            <b>Note: </b><span>{{ event?.note }}</span>
          </div>
          <div *ngIf="event.connectionInfo">
            <b>Link: </b><span>{{ event?.connectionInfo }}</span>
          </div>
        </div>
        <div class="col-6"></div>
      </div>
    </div>
    <div mat-dialog-actions class="text-end">
      <button
        type="button"
        (click)="close()"
        class="m-1 btn btn-secondary rounded"
      >
        Indietro
      </button>
      <button
        type="button"
        (click)="save()"
        class="m-1 btn btn-secondary rounded"
      >
        Conferma
      </button>
    </div>
  `,
  styles: [],
})
export class DeleteEventModalComponent implements OnInit {
  event: IEvent;
  constructor(
    public dialogRef: MatDialogRef<DeleteEventModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { event: IEvent }
  ) {}

  ngOnInit(): void {
    this.event = this.data.event;
  }
  close() {
    this.dialogRef.close(false);
  }
  save() {
    this.dialogRef.close(true);
  }
}
