export enum RecruitRoles {
  ADMIN = 'is_admin',
  STORE_MANAGER = 'is_store_manager',
  HR_OFFICE = 'is_ufficio_hr',
  CONSUMER = 'is_consumer_api',
  DIRECTOR = 'is_director',
  AREA_MANAGER = 'is_area_manager',
  SHOW_ROOM_MANAGER = 'is_show_room_manager',
  CANDIDATE_VIEWER = 'candidate_viewer',
  CANDIDATE_MANAGER = 'candidate_manager',
  CANDIDATE_DELETER = 'candidate_deleter',
  CANDIDATE_CONSUMER_VIEWER = 'candidate_consumer_viewer',
  CANDIDATE_CONSUMER_MANAGER = 'candidate_consumer_manager',
  CANDIDATE_CONSUMER_DELETER = 'candidate_consumer_deleter',
  EXCLUDE_FROM_ASSIGNMENT = 'exclude_from_assignment',
  EXCLUDE_FROM_INTERVIEW_NOTIFICATION = 'exclude_from_interview_notification',
  INTERVIEW_RESULT_VIEWER = 'interview_result_viewer',
  ASSISTANCE_EMAIL = 'assistance_email',
  SUPERVISOR = 'supervisor',
  COORDINATOR = 'is_coordinator',
}
export enum RecruitRolesLabels {
  'is_admin' = 'Admin',
  'is_ufficio_hr' = 'HR',
  'is_director' = 'Direttore Commerciale',
  'is_area_manager' = 'Area Manager',
  'is_show_room_manager' = 'Showroom Manager',
  'is_store_manager' = 'Store Manager',
  'is_coordinator' = 'Coordinatore SM',
}

export const RecruitRolesLevels = [
  RecruitRoles.STORE_MANAGER,
  RecruitRoles.SHOW_ROOM_MANAGER,
  RecruitRoles.AREA_MANAGER,
  RecruitRoles.DIRECTOR,
];
