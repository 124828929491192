import { IUtentiModuleService } from 'src/libs/fe/core-ng/src/lib/features';
import { IUser } from 'src/libs/shared/common/src';
import {
  RecruitFimmRolesLevels,
  RecruitFimmRoles,
} from 'src/libs/shared/recruit/src';

export class UtentiService implements IUtentiModuleService {
  calculateNetworkPriority(user: IUser, applicationId: string) {
    const reg = user.registrations?.find(
      (r) => r.applicationId == applicationId
    );

    const roles = reg?.roles;

    let maxLevel = 0;
    roles?.forEach((r) => {
      const level = RecruitFimmRolesLevels.indexOf(<RecruitFimmRoles>r);
      if (maxLevel < level) {
        maxLevel = level;
      }
    });

    return RecruitFimmRolesLevels.slice(maxLevel + 1);
  }
}
