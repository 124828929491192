export interface ITimelineUiItem {
  operator: string;
  type: string;
  createdAt: Date;
  title: string;
  description: string | unknown[];
  icon?: string;
  agent?: string;
  user?: string;
  extras?: unknown;
}
