import {
  Component,
  ContentChildren,
  Directive,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';
import { AfterContentInit } from '@angular/core';

@Directive({
  selector: 'checkbox',
})
export class CheckboxButtonDirective {
  @Input() label;
  @Input() value;
}

@Component({
  selector: 'fullstack-checkbox-button',
  templateUrl: './checkbox-button.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxButtonComponent),
      multi: true,
    },
  ],
})
export class CheckboxButtonComponent
  extends FormElement
  implements OnInit, AfterContentInit
{
  @ContentChildren(CheckboxButtonDirective) checkboxButtons: QueryList<any>;
  @Input() options: { label: String; placeholder: String };

  selectedIndex;
  isDisabled;
  ngOnInit(): void {}

  ngAfterContentInit() {
    this.writeValue(this.value);
  }

  writeValue(val): void {
    this.value = val;
    if (this.checkboxButtons) {
      let i = this.checkboxButtons.toArray().findIndex((c) => c.value == val);
      this.selectedIndex = i;
    }
  }

  onSelect(index) {
    this.selectedIndex = index;
    this.value = this.checkboxButtons.toArray()[this.selectedIndex].value;
    this._onChange(this.value);
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
