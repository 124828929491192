import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IFileElement } from 'src/libs/shared/common/src';

@Injectable()
export class FileManagerService {
  constructor(private httpClient: HttpClient, @Inject('FILE_M_CONF') public conf: { endpoint: string }) {}

  add(IFileElement: IFileElement) {
    return this.httpClient.post<IFileElement>(this.conf.endpoint + '/file-manager', IFileElement);
  }

  delete(id: string) {
    return this.httpClient.delete<IFileElement>(this.conf.endpoint + '/file-manager/' + id);
  }

  update(id: string, update: Partial<IFileElement>) {
    return this.httpClient.patch<IFileElement>(this.conf.endpoint + '/file-manager/' + id, update);
  }

  queryInFolder(folderId: string) {
    return this.httpClient.get<IFileElement[]>(this.conf.endpoint + '/file-manager/parent/' + folderId);
  }

  get(id: string) {
    return this.httpClient.get<IFileElement>(this.conf.endpoint + '/file-manager/' + id);
  }

  clone(element: IFileElement) {
    return JSON.parse(JSON.stringify(element));
  }
}
