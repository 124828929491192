export interface IStatisticCandidateTotal {
  architect: IStatisticCandidate;
  worker: IStatisticCandidate;
  company: IStatisticCandidate;
  totals: IStatisticCandidate;
}
export interface IStatisticCandidate {
  candidatesAssigned: number;
  candidatesToWork: number;
  candidatesInProgress: number;
  candidatesOk: number;
  candidatesKoStoreManager: number;
  candidatesKoHr: number;
}

export interface ISmStatistic {
  toWork: number;
  koCv: number;
  appointment: number;
  appointmentOK: number;
  appointmentKO: number;
  partenershipSet: number;
  partenershipKO: number;
  doc: number;
  docKO: number;
  operative: number;
}
export interface INamedStatistic extends ISmStatistic {
  name: string;
  role: string;
  total?: number;
  operator: string;
}

export interface IBacklogStatistic {
  interview: number;
  koByHr: number;
  wip: number;
  toWork: number;
}

export type GroupSelector = 'appointment' | 'partnershipSet' | 'toWork';
export type SmQueryParams = {
  start: Date;
  end: Date;
  type: string;
  id?: string;
  groupSelector?: GroupSelector;
};
