import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IContactIndividual } from 'src/libs/shared/common/src';
import {
  CreateCandidate,
  IBaseCandidate,
  ICandidateSearch,
  RecruitFimmStateCodes,
} from 'src/libs/shared/recruit/src';
import { FilesService } from 'src/libs/fe/core-ng/src';
import dayjs from 'dayjs';
import { isEmpty, isNil, isNull } from 'lodash';

import { MessageService } from 'primeng/api';
import {
  catchError,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  reduce,
  switchMap,
  tap,
  zip,
} from 'rxjs';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from '../../environments/environment';
@Injectable()
export class CandidatiApiService {
  env = environment;
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private fileService: FilesService
  ) {}

  saveCandidate(candidate: CreateCandidate): Observable<CreateCandidate> {
    return this.http
      .post<CreateCandidate>(
        `${environment.apiGatewayURL}/candidates`,
        candidate
      )
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Candidato creato con successo',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }
  updateCandidate(dto: {
    candidate: Partial<IBaseCandidate>;
    contact: Partial<IContactIndividual>;
  }): Observable<CreateCandidate> {
    return this.http
      .patch<IBaseCandidate>(`${environment.apiGatewayURL}/candidates`, dto)
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Candidato aggiornato con successo',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }

  getCandidateList$(
    payload: ICandidateSearch
  ): Observable<{ items: IBaseCandidate[]; total: number }> {
    const filter = this.buildFilter(payload);
    return this.http
      .post<{ items: IBaseCandidate[]; total: number }>(
        `${environment.apiGatewayURL}/candidates/browse`,
        {
          offset: payload.offset,
          limit: payload.limit,
          sort: [payload.sort],
          filter,
        }
      )
      .pipe(
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }
  getTrainableCanidate$(): Observable<{
    items: IBaseCandidate[];
    total: number;
  }> {
    return this.http
      .post<{ items: IBaseCandidate[]; total: number }>(
        `${environment.apiGatewayURL}/candidates/browse`,
        {
          offset: 0,
          limit: 999,
          sort: [
            {
              by: 'cache.contact.lastName',
              order: 1,
            },
          ],
          filter: {
            'status.code': {
              $in: [RecruitFimmStateCodes.SM_OK_DOCUMENTAZIONE_INVIATA],
            },
          },
        }
      )
      .pipe(
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }

  private buildFilter(payload: { limit: number; offset: number; filter: any }) {
    const filter = {};
    if (!isEmpty(payload?.filter)) {
      if (!isEmpty(payload?.filter?._id)) {
        filter['_id'] = {
          $eq: payload.filter._id,
        };
      }
      if (
        !isNil(payload?.filter?.createdAt?.start) &&
        !isNil(payload?.filter?.createdAt?.end)
      ) {
        filter['createdAt'] = {
          $gte: dayjs(payload.filter['createdAt'].start).startOf('day'),
          $lte: dayjs(payload.filter['createdAt'].end).endOf('day'),
        };
      }
      if (
        !isNil(payload?.filter.assignedAt?.start) &&
        !isNil(payload?.filter.assignedAt?.end)
      ) {
        filter['assignedAt'] = {
          $gte: dayjs(payload.filter['assignedAt'].start).startOf('day'),
          $lte: dayjs(payload.filter['assignedAt'].end).endOf('day'),
        };
      }
      if (!isEmpty(payload?.filter['status.code'])) {
        filter['status.code'] = {
          $eq: payload.filter['status.code'],
        };
      }
      if (!isEmpty(payload?.filter['addressOp.locality'])) {
        filter['addressOp.locality'] = {
          $regex: payload.filter['addressOp.locality'],
          $options: 'i',
        };
      }
      if (!isEmpty(payload?.filter['firstName'])) {
        filter['cache.contact.firstName'] = {
          $regex: payload.filter['firstName'],
          $options: 'i',
        };
      }
      if (!isEmpty(payload?.filter['lastName'])) {
        filter['cache.contact.lastName'] = {
          $regex: payload.filter['lastName'],
          $options: 'i',
        };
      }
      if (!isEmpty(payload?.filter['phone'])) {
        filter['cache.contact.phones.phoneNumber'] = {
          $regex: payload.filter['phone'],
          $options: 'i',
        };
      }
      if (!isEmpty(payload?.filter['email'])) {
        filter['cache.contact.emails.email'] = {
          $regex: payload.filter['email'],
          $options: 'i',
        };
      }
      if (!isEmpty(payload?.filter['profile.lastWorkingExperience'])) {
        filter['profile.lastWorkingExperience'] = {
          $regex: payload.filter['profile.lastWorkingExperience'],
          $options: 'i',
        };
      }
      if (!isNil(payload?.filter['privacy.marketing'])) {
        filter['privacy.marketing'] = {
          $eq: payload.filter['privacy.marketing'],
        };
      }
      if (!isNil(payload?.filter['privacy.commercial'])) {
        filter['privacy.commercial'] = {
          $eq: payload.filter['privacy.commercial'],
        };
      }
      if (!isNil(payload?.filter['privacy.profile'])) {
        filter['privacy.profile'] = {
          $eq: payload.filter['privacy.profile'],
        };
      }
      if (!isNil(payload?.filter['privacy.otherSub'])) {
        filter['privacy.otherSub'] = {
          $eq: payload.filter['privacy.otherSub'],
        };
      }
      if (!isEmpty(payload?.filter['assigned'])) {
        filter['assigned'] = {
          $eq: payload.filter['assigned'],
        };
      }
      if (!isEmpty(payload?.filter['assignedHR'])) {
        filter['assignedHR'] = {
          $eq: payload.filter['assignedHR'],
        };
      }
      if (!isEmpty(payload?.filter['tracking.utmCampaign'])) {
        filter['tracking.utmCampaign'] = {
          $eq: payload.filter['tracking.utmCampaign'],
        };
      }
      if (!isEmpty(payload?.filter['tracking.utmSource'])) {
        filter['tracking.utmSource'] = {
          $eq: payload.filter['tracking.utmSource'],
        };
      }
    }
    return filter;
  }

  getCandidateById$(id: string): Observable<IBaseCandidate> {
    return this.http
      .get<IBaseCandidate>(`${environment.apiGatewayURL}/candidates/${id}`)
      .pipe(
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }

  getCandidateByContact$(id: string): Observable<IBaseCandidate> {
    return this.http
      .get<IBaseCandidate>(
        `${environment.apiGatewayURL}/candidates/contact/${id}`
      )
      .pipe(
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }

  changeState(
    candidateId: string,
    status: { code: RecruitFimmStateCodes; data: any }
  ): Observable<any> {
    return this.http
      .post<any>(`${environment.apiGatewayURL}/candidates/status`, {
        id: candidateId,
        status,
      })
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Candidato aggiornato con successo',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }
  getCandidateHistory(id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiGatewayURL}/candidates/history/${id}`
    );
  }
  getCandidateProfile(id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiGatewayURL}/candidates/profile/${id}`
    );
  }
  updateProfile(id: string, profile: any): Observable<any> {
    return this.http
      .patch<any>(`${environment.apiGatewayURL}/candidates/profile/${id}`, {
        profile,
      })
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Profilo aggiornato con successo',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }
  addFile(files: FileList) {
    return this.fileService.uploadFiles(files).pipe(
      filter((res) => res.length > 0 && res[0].length > 0),
      map((matrix) => matrix.reduce((a, b) => a.concat(b), [])),
      mergeMap((ids) =>
        zip(
          ids.map((id, index) =>
            this.fileService
              .update(id, { name: files[index].name })
              .pipe(switchMap(() => this.fileService.searchById(id)))
          )
        )
      ),
      reduce((a, b) => a.concat(b), [])
    );
  }

  updateFile(id: string, files: string[]) {
    return this.http.put<any>(
      `${environment.apiGatewayURL}/candidates/files/${id}`,
      {
        fileIds: files,
      }
    );
  }
  getCandidateFiles(candidate: IBaseCandidate) {
    if (!candidate?.attachments || candidate?.attachments.length == 0)
      return of([]);
    const sources$ = candidate?.attachments.map((id) => {
      return this.fileService.searchById(id);
    });
    return zip(sources$);
  }
  sendDocumentation(id: string) {
    return this.http.post(
      `${environment.apiGatewayURL}/candidates/sendDocuments`,
      { id }
    );
  }
}
