export enum RecruitFimmRoles {
  ADMIN = 'is_admin',
  CC_MANAGER = 'is_cc_manager',
  HR_OPERATOR = 'is_hr_operator',
  HR_JR = 'is_hr_jr',
  AUTOASSIGN = 'auto_assign',
}
export enum RecruitFimmRolesLabels {
  'is_admin' = 'Admin',
  'is_hr_operator' = 'Operatore Telefonico',
  'is_cc_manager' = 'CC  Manager',
  'is_hr_jr' = 'Hr Jr',
}

export const RecruitFimmRolesLevels = [
  RecruitFimmRoles.CC_MANAGER,
  'is_area_manager',
  'is_regional_manager',
];

export function getUplineRecruitFimm(roleStart: RecruitFimmRoles) {
  let roleLevelStartIndex = RecruitFimmRolesLevels.findIndex(
    (r) => r == roleStart
  );
  return RecruitFimmRolesLevels.slice(roleLevelStartIndex);
}
