import {
  AfterContentInit,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FilesService } from 'src/libs/fe/core-ng/src';
import { FileUpload } from 'primeng/fileupload';
import { filter, switchMap, tap } from 'rxjs';
import { FormElement } from '../core/utils/form-element';
import { ConfirmModalComponent } from '../modals';
import { InputFileRowDirective } from './input-file-upload.directive';

@Component({
  selector: 'fullstack-file-upload-new',
  templateUrl: './input-file-upload.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileUploadComponent),
      multi: true,
    },
  ],
  styles: [``],
})
export class InputFileUploadComponent extends FormElement implements AfterContentInit {
  @ViewChild('fileUpload') fileUpload: FileUpload;

  @Input() options: { label: string };
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() accept = 'image/*';
  @Input() maxFileSize = 20000000;
  @Input() uploadApiUrl: string;
  @Input() storageUrl: string;
  @Input() fileLimit = 3;
  @Input() fileList: any[];
  @Input() singleView = false;
  @Input() readonly = false;
  @Output() onFilesSelect = new EventEmitter();
  @Output() onSaveChanges = new EventEmitter();
  @Output() fileDeleted = new EventEmitter();
  @ContentChild(InputFileRowDirective) rowTemplate: InputFileRowDirective;

  filesToUpload: FileList;
  inputId: string;
  constructor(private dialog: MatDialog, private filesService: FilesService) {
    super();
    this.inputId = 'id_' + Math.random().toString();
  }

  ngOnInit(): void {}

  ngAfterContentInit(): void {}

  delete(i: number) {
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          text: `Confermi di voler eliminare questo elemento?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res && res.confirmed),
        switchMap(() => this.filesService.delete(this.fileList[i].id)),
        tap(() => this.fileDeleted.emit(this.fileList[i].id))
      )
      .subscribe(() => this.fileList.splice(i, 1));
  }

  open(i: number) {
    this.filesService.download(this.fileList[i].id);
  }

  download(i: number) {
    this.filesService.downloadAsAttachment(this.fileList[i].id);
  }
}
