export enum DashboardLabel {
  IN_OPERATIVO = 'New Arch',
  IN_AFFIANCAMENTO_KO = 'Affiancamento eseguito KO',
  IN_AFFIANCAMENTO_ESEGUITO_OK = 'Affiancamento eseguito OK',
  IN_AFFIANCAMENTO_IN_CORSO = 'Affiancamento in corso',
  IN_AFFIANCAMENTO_FISSATO = 'Affiancamento fissato',
  IN_AFFIANCAMENTO_NON_PRESENTATO = 'Affiancamento non presentato',
  IN_AFFIANCAMENTO_DA_FISSARE = 'Affiancamento da fissare',
  IN_COLLOQUIO_ESEGUITO_KO = 'Colloquio eseguito KO',
  IN_COLLOQUIO_ESEGUITO_OK = 'Colloquio eseguito OK',
  IN_COLLOQUIO_PRESENTATO = 'Colloquio presentato',
  IN_RICHIAMATO_KO = 'Colloquio richiamato KO',
  IN_COLLOQUIO_NON_PRESENTATO = 'Colloquio non presentato',
  IN_DA_PRESENTARE = 'Colloquio da Presentare',
  IN_COLLOQUIO_FISSATO = 'Colloquio Fissato',

  //imprese
  IN_KO_DOCUMENTI = 'Documenti KO',
  IN_KO_RICHIESTA_DOCUMENTI = 'Richiesta Documenti',
  IN_KO_PREZZO = 'KO Prezzo',
  IN_KO_NO_INTERESSATO = 'Non Interessato',
}

//VERDE '#8ac92680',
//ROSSO '#ff595e80',
//GIALLO '#ffca3a80'
export enum DashboardLabelColors {
  IN_OPERATIVO = '#8ac92680', //VERDE
  IN_KO_DOCUMENTI = '#ff595e80',
  IN_KO_RICHIESTA_DOCUMENTI = '#ffca3a80',
  IN_KO_PREZZO = '#ff595e80',
  IN_KO_NO_INTERESSATO = '#ff595e80',
  IN_AFFIANCAMENTO_KO = '#ff595e80',
  IN_AFFIANCAMENTO_ESEGUITO_OK = '#ffca3a80',
  IN_AFFIANCAMENTO_IN_CORSO = '#ffca3a80',
  IN_AFFIANCAMENTO_FISSATO = '#8ac92680',
  IN_AFFIANCAMENTO_NON_PRESENTATO = '#ffca3a80',
  IN_AFFIANCAMENTO_DA_FISSARE = '#ffca3a80',
  IN_COLLOQUIO_ESEGUITO_KO = '#ff595e80',
  IN_COLLOQUIO_ESEGUITO_OK = '#ffca3a80',
  IN_COLLOQUIO_PRESENTATO = '#ffca3a80',
  IN_RICHIAMATO_KO = '#ffca3a80',
  IN_COLLOQUIO_NON_PRESENTATO = '#ffca3a80', //ROSSO
  IN_DA_PRESENTARE = '#ffca3a80',
  IN_COLLOQUIO_FISSATO = '#8ac92680',
}
