import { IUser } from 'src/libs/shared/common/src';

export interface IMedia {
  // FIXME: extends interface for object, id
  readonly object?: string;
  id?: string;

  file: IFile | string;

  // FIXME: extends mongoose interface
  createdBy?: IUser | string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IFile {
  fieldname?: string;
  originalname?: string;
  encoding?: string;
  mimetype?: string;
  destination?: string;
  filename?: string;
  path?: string;
  size?: number;
}
