<label class="card card--form">
  <span class="card-body">
    <span class="label-control">{{ options.label }}</span>
    <div class="row">
      <div class="col">
        <input
          class="form-control"
          [ngxTimepicker]="toggleTimepicker"
          [format]="24"
          [disableClick]="true"
          readonly
          [ngModel]="value"
          (ngModelChange)="_onChange($event)"
          [disabled]="isDisabled"
          placeholder="{{ options.placeholder }}"
        />
      </div>
      <div class="col-auto">
        <div class="toggle-example">
          <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
          <ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker>
        </div>
      </div>
    </div>
  </span>
</label>
