import { NgModule } from '@angular/core';
import {
  CalendarioModule,
  DomainValuesModule,
  GuardsModule,
  NetworkModule,
  ProfileModule,
  SiteModule,
  SourceModule,
  UtentiModule,
} from 'src/libs/fe/core-ng/src';
import { environment } from '../../environments/environment';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { UtentiService } from '../services/utenti.service';
import { InterviewModule } from './interview/interview.module';
import { RecruitFimmEventModal } from '../modals/recruitfimm-event-modal';
import { PipesModule } from '../pipes/pipes.module';
import { LabelRoles } from '@renovars/common';
import { SourcesServices } from '@renovars/fe-core-libs/services/sources.service';

const DECLARATIONS = [];

@NgModule({
  declarations: DECLARATIONS,
  imports: [
    HomeModule,
    LoginModule,
    GuardsModule,
    UtentiModule.forRoot({
      userMetaEndpoint: `${environment.apiGatewayURL}/user-meta`,
      service: new UtentiService(),
      excludeField: {
        site: false,
        bc: true,
        acceptStar: true,
      },
    }),
    NetworkModule.forRoot({
      networkURL: `${environment.apiGatewayURL}/network`,
      labels: LabelRoles,
    }),
    InterviewModule,
    DomainValuesModule.forRoot({
      apiApplicationURL: `${environment.apiGatewayURL}`,
    }),
    ProfileModule,
    CalendarioModule.forRoot({
      eventModalClass: RecruitFimmEventModal,
      eventsEndpoint: `${environment.apiGatewayURL}/events`,
    }),
    PipesModule,
    SiteModule.forRoot({
      url: `${environment.apiGatewayURL}`,
      exclude: { bcCode: true, phone: true, area: false },
    }),
    SourceModule,
  ],
  providers: [SourcesServices],
  exports: [...DECLARATIONS],
})
export class FeauturesModule {}
